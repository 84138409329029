import loadable from '@loadable/component';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import _isEmpty from 'lodash/isEmpty';

import Button from 'react-bootstrap/Button';

import acl from 'tools/acl';

import Banner from 'components/Banner';
import Loading, { LoadingSmall } from 'components/Loading';

import CommunSVGs from 'svgs/commun';

import ArtworkCardContent from '../ArtworkCardContent';
import './ArtworkCard.scss';

const FlowFolder = loadable(() => import('components/Folder/FlowFolder'), { fallback: <LoadingSmall /> });
const PackFolder = loadable(() => import('components/Folder/PackFolder'), { fallback: <LoadingSmall /> });

type Props = {
  artwork: Object,
  userProfile: Object,
};

export default function ArtworkCard({ artwork, userProfile }: Props) {
  const history = useHistory();
  const { t } = useTranslation('artworkcard');

  const handleStartFlow = () => {
    history.push(`/flow/${artwork.id}`);
  };

  if (_isEmpty(artwork)) {
    return <Loading />;
  }

  const isUserArtwork = acl.isAuthor(userProfile, artwork);
  return (
    <div className="ArtworkCard card">
      <Banner
        className="ArtworkCard-banner"
        artwork={artwork}
        userProfile={userProfile}
        onGoBack={() => history.back()}
      />

      <ArtworkCardContent className="ArtworkCard-content" artwork={artwork} />

      {(artwork.isFlow || artwork.packs) && (
        <div className="ArtworkCard-folders">
          {artwork.isFlow && <FlowFolder className="ArtworkCard-flow" flow={artwork} userProfile={userProfile} />}

          {artwork.packs && (
            <div className="ArtworkCard-packs">
              {artwork.packs.map(pack => (
                <PackFolder
                  className="ArtworkCard-pack"
                  key={pack.id}
                  pack={pack}
                  artwork={artwork}
                  userProfile={userProfile}
                />
              ))}
            </div>
          )}

          {isUserArtwork && (
            <>
              {!artwork.isFlow && (
                <div className="text-center">
                  <Button
                    variant="link"
                    className="Tab-button mt-4 flowfont-medium text-primary"
                    onClick={handleStartFlow}
                  >
                    <CommunSVGs.Flow width="20px" height="20px" fill="var(--primary)" className="mr-2" />
                    {t('create-flow')}
                  </Button>
                </div>
              )}

              <div className="text-center flowfont-medium mt-2">
                <Link to={`/pack/add/artwork/${artwork.id}`} className="Tab-link text-secondary">
                  <CommunSVGs.Pack width="20px" height="20px" fill="var(--secondary)" className="mr-2" />
                  {t('create-pack')}
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

import React from 'react';

import { Link } from 'react-router-dom';

import _get from 'lodash/get';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import ArtworkFavorites from 'components/Artwork/ArtworkFavorites';
import ArtworkHeaderActions from 'components/Artwork/ArtworkHeaderActions';
import Avatar from 'components/Avatar';

import ArtworkInfo from './ArtworkInfo';

type Props = {
  artwork: Object,
  userProfile: Object,
  isGallery: Boolean,
};

const ArtworkProfile = ({ artwork, userProfile, isGallery }: Props) => {
  const isUserArtwork = acl.isAuthor(userProfile, artwork);
  const profile = isUserArtwork ? userProfile : artwork.author;
  return (
    <div className="Artwork-profile">
      {/* Right panel only desktop view */}
      <div className="Artwork-cover-wrapper">
        <div className="Artwork-actions">
          <ArtworkHeaderActions artwork={artwork} />
        </div>
        <div className="Artwork-cover">
          <img src={artworkUtils.getCoverUrl(artwork, 238)} alt={artwork.name} />
        </div>
      </div>
      <div className="Artwork-profile-info">
        <ArtworkFavorites
          artwork={artwork}
          userId={_get(userProfile, 'id')}
          className="Artwork-favorites mt-2"
          isProfile
        />
        <ArtworkInfo artwork={artwork} />
      </div>
      <div className="Artwork-user">
        <Link to={`/profile/${artwork.author.username}`} className="d-flex align-self-center justify-content-center">
          <Avatar
            src={artworkUtils.getAvatarUrl(_get(artwork, 'author.avatarFile', 74))}
            alt="User"
            className="Artwork-avatar"
          />
          <div className="Artwork-username h5 flowfont-bold text-secondary">{artwork.author.username}</div>
        </Link>
      </div>
      <div className="Artwork-title">
        <OverlayTrigger
          key="readfull"
          placement="bottom"
          trigger={['hover', 'focus']}
          delay={{ show: 500 }}
          overlay={<Tooltip className="FolderFile-tooltipBlue">{artwork.name}</Tooltip>}
        >
          <h1 className="h2 flowfont-title text-primary mb-1 mt-2">
            <Link to={`/artwork/${profile.username}/${artwork.uniqueName}${isGallery ? '/news' : ''}`}>
              {artwork.name}
            </Link>
          </h1>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default ArtworkProfile;

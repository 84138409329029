import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { MEDIA_LG } from 'constants/Constants';

import ArtworkSuggestion from './ArtworkSuggestion';
import './ArtworkSuggestions.scss';

type Props = {
  suggestions: [],
  shouldUseBanner: Boolean,
  userProfile: Object,
  onNotInterested: Function,
};

const ArtworkSuggestions = ({ suggestions, shouldUseBanner, userProfile, onNotInterested }: Props) => {
  const { t } = useTranslation();
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });
  return (
    <div className={`Artwork-suggestions ${shouldUseBanner ? ' banner' : ''}`}>
      <div className="Artwork-suggestions-title ">{t('global:suggestions')}</div>
      <div className={`d-flex flex-row${shouldUseBanner ? '' : ' flex-xl-column'} flex-wrap justify-content-center`}>
        {suggestions &&
          suggestions
            .slice(0, 3)
            .map(artwork => (
              <ArtworkSuggestion
                key={artwork.id}
                suggestion={artwork}
                shouldUseBanner={shouldUseBanner && !isMobile}
                userProfile={userProfile}
                onNotInterested={onNotInterested}
              />
            ))}
        <div className="Artwork-suggestions-title-card">{t('global:suggestions')}</div>
        {suggestions &&
          suggestions
            .slice(3, shouldUseBanner ? 5 : 6)
            .map(artwork => (
              <ArtworkSuggestion
                key={artwork.id}
                suggestion={artwork}
                shouldUseBanner={shouldUseBanner && !isMobile}
                userProfile={userProfile}
                onNotInterested={onNotInterested}
              />
            ))}
      </div>
    </div>
  );
};

export default ArtworkSuggestions;

import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import acl from 'tools/acl';

import { acceptArtwork } from 'actions/UserActions';

import Banner from 'components/Banner';

import CommunSVGs from 'svgs/commun';

import './ArtworkDisclaimer.scss';

type Props = {
  artwork: Object,
  userProfile: Object,
  closeLabel: String,
  onAccept(): void,
  onClose(): void,
};

export default function ArtworkDisclaimer({ artwork, closeLabel, userProfile, onAccept, onClose }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('artworkdisclaimer');

  const handleAccept = e => {
    if (acl.isConnected(userProfile)) {
      dispatch(acceptArtwork(artwork.id));
    }
    if (onAccept) {
      onAccept(e);
    }
  };

  const handleClose = e => {
    if (onClose) {
      onClose(e);
    } else {
      history.goBack();
    }
  };

  return (
    <Modal
      show
      centered
      backdrop="static"
      backdropClassName="blur-modal-backdrop"
      dialogClassName="Artwork-Modal ArtworkDisclaimer"
    >
      <Modal.Body>
        <Banner artwork={artwork} className="ArtworkDisclaimer-banner" onGoBack={() => history.goBack()} />
        <div className="ArtworkDisclaimer-content">
          <div className="ArtworkDisclaimer-title h3 flowfont-title d-flex justify-content-center text-danger">
            {t('title')} <img src="/assets/icons/-18.png" alt="-18" width="30" height="30" className="ml-2" />
          </div>
          <p className="ArtworkDisclaimer-description flowfont-regular text-neutral">{t('description')}</p>
          <p className="ArtworkDisclaimer-exit">
            <Button className="btn btn-pill" onClick={handleClose}>
              <CommunSVGs.ArrowLeft height="24" fill="white" /> {closeLabel || t('close-button')}
            </Button>
          </p>
          <Button
            className="ArtworkDisclaimer-accept flowfont-medium fs-113 text-secondary"
            onClick={handleAccept}
            variant="link"
          >
            {t('accept-button')}
          </Button>
          <p className="ArtworkDisclaimer-footer flowfont-thin text-neutralSubtle mt-2">{t('footer')}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

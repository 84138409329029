import React from 'react';

import Tab from 'react-bootstrap/Tab';

type Props = {
  eventKey: String,
  title: Function,
  href: String,
  className: String,
  children: any,
};

const CustomTab = ({ eventKey, title, href, className, children }: Props) => {
  return (
    <Tab eventKey={eventKey} href={href} title={title} className={className}>
      {children}
    </Tab>
  );
};

export default React.memo(CustomTab);

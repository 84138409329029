import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _inludes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';

import { Formik, Form, Field } from 'formik';

import Button from 'react-bootstrap/Button';

import { THEME_DARK } from 'constants/Constants';

import acl from 'tools/acl';
import seo from 'tools/seo';
import { withTheme } from 'tools/theme';

import { getAllArtworks } from 'actions/ArtworkActions';
import { getCategories, getLanguages } from 'actions/TaxonomyActions';

import ArtworkCardList from 'components/ArtworkCardList';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { LoadingButton, LoadingSmall, LoadingBig as Loading } from 'components/Loading';

import CommunSVGs from 'svgs/commun';

import { getLinkPrefix } from '../../i18n/i18n';
import './Discover.scss';

const DEFAULT_ORDER = 'updated';

type Props = {
  userProfile: Object,
  acceptedArtworks: [],
  artworks: Array,
  artworksTotal: Number,
  allArtworksLast: Number,
  artworksIsFetching: Boolean,
  languagesList: [],
  categoriesList: [],
  match: Object,
  history: Object,
  location: Object,
  i18n: Object,
  theme: string,
  t: Function,
  getAllArtworksAction: Function,
  getLanguagesAction: Function,
  getCategoriesAction: Function,
};

class Discover extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
    if (typeof document === 'undefined' && !props.artworks) {
      this.updateUrl(); // SSR
    }
  }

  componentDidMount() {
    const { categoriesList, languagesList, getLanguagesAction, getCategoriesAction } = this.props;
    if (_isEmpty(categoriesList)) {
      getCategoriesAction();
    }
    if (_isEmpty(languagesList)) {
      getLanguagesAction();
    }
    this.updateUrl();
  }

  getSearchParams = () => {
    const {
      userProfile,
      location,
      match: {
        params: { category },
      },
    } = this.props;
    const queryParam = new URLSearchParams(location.search);
    const params = {
      search: queryParam.get('search'),
      orderBy: queryParam.get('order'),
      languages: !_isNil(queryParam.get('languages'))
        ? queryParam.get('languages')
        : _get(userProfile, 'defaultSearchLanguages'),
      categories: !_isNil(queryParam.get('categories')) ? queryParam.get('categories') : category,
      exclusions: !_isNil(queryParam.get('exclusions'))
        ? queryParam.get('exclusions')
        : _get(userProfile, 'defaultSearchExclusions'),
    };
    const defaultUserExclusions = !acl.isConnected(userProfile) || _get(userProfile, 'isAdult') ? [] : ['adult'];
    return {
      search: params.search || '',
      page: 1,
      orderBy: params.orderBy || DEFAULT_ORDER,
      languages: _filter(!_isNil(params.languages) ? params.languages.split(',') : [], e => e !== ''),
      categories: _filter(!_isNil(params.categories) ? params.categories.split(',') : [], e => e !== ''),
      exclusions: _filter(
        !_isNil(params.exclusions) ? params.exclusions.split(',') : defaultUserExclusions,
        e => e !== '',
      ),
    };
  };

  loadMore = async () => {
    const { artworksIsFetching, allArtworksLast, getAllArtworksAction } = this.props;
    const { page } = this.state;
    const currentPage = page + 1;
    if (artworksIsFetching || currentPage > allArtworksLast) {
      return;
    }
    const { search, orderBy, languages, categories, exclusions } = this.getSearchParams();
    this.setState({ page: currentPage });
    await getAllArtworksAction(search, currentPage, orderBy, languages, categories, exclusions);
  };

  updateUrl = searchParams => {
    const { history, getAllArtworksAction } = this.props;
    const { page } = this.state;

    const currentSearchParams = this.getSearchParams();
    const searchChanged = !_isEqual(currentSearchParams, searchParams);
    const { search, orderBy, languages, categories, exclusions } = searchParams || currentSearchParams;

    let searchQuery = '/discover';
    if (!_isEmpty(categories) && categories.length === 1) {
      searchQuery += `/${categories[0]}`;
    }
    searchQuery += `?search=${search}&order=${orderBy}`;
    if (categories.length > 1) searchQuery += `&categories=${categories.join(',')}`;
    searchQuery += `&languages=${languages.join(',')}`;
    searchQuery += `&exclusions=${exclusions.join(',')}`;

    history.push(searchQuery);
    if (searchChanged && page !== 1) {
      this.setState({ page: 1 });
      if (typeof document !== 'undefined') {
        window.scrollTo(0, 0);
      }
    }
    getAllArtworksAction(search, searchChanged ? 1 : page, orderBy, languages, categories, exclusions);
  };

  changeOrderBy = orderBy => {
    const searchParams = this.getSearchParams();
    searchParams.orderBy = orderBy;
    this.updateUrl(searchParams);
  };

  toggleExclusion = exclusion => {
    const searchParams = this.getSearchParams();
    const { exclusions } = searchParams;
    if (_find(exclusions, e => e === exclusion)) {
      searchParams.exclusions = exclusions.filter(e => e !== exclusion);
    } else {
      searchParams.exclusions = [...exclusions, exclusion];
    }
    this.updateUrl(searchParams);
  };

  handleUpdate = event => {
    if (event && event.type === 'cat') {
      const searchParams = this.getSearchParams();
      searchParams.categories = [...searchParams.categories.filter(c => c !== event.value), event.value];
      this.updateUrl(searchParams);
    }
  };

  handleSubmit = values => {
    if (values.search || values.search === '') {
      const searchParams = this.getSearchParams();
      searchParams.search = values.search;
      searchParams.languages = _map(
        _filter(values.languages, l => !!l),
        l => l.label,
      );
      searchParams.categories = _map(
        _filter(values.categories, c => !!c),
        c => c.label,
      );
      this.updateUrl(searchParams);
    }
  };

  renderSearch = () => {
    const {
      match: {
        params: { category },
      },
      artworks,
      artworksTotal,
      artworksIsFetching,
      categoriesList,
      languagesList,
      i18n,
      theme,
      t,
    } = this.props;
    if (!artworks) {
      return null;
    }
    const { search, orderBy, languages, categories, exclusions } = this.getSearchParams();
    const categoriesListValues = _map(categoriesList, o => ({ value: o.id, label: o.name }));
    const languagesListValues = _map(languagesList, o => ({ value: o.id, label: o.name }));
    const initialValues = {
      search,
      languages: _map(languages, l => _find(languagesListValues, ll => ll.label === l)),
      categories: _map(categories, c => _find(categoriesListValues, cl => cl.label === c)),
    };
    const langPrefix = getLinkPrefix(i18n);
    return (
      <div className="Page-card Discover-search-card d-flex flex-column flex-sm-row flex-lg-column justify-content-center justify-content-lg-start">
        <div className="Discover-search-logo">
          <Link to={`${langPrefix}/`} className="Discover-search-img">
            <img src={`/assets/images/flowfo-search${theme === THEME_DARK ? '-darkmode' : ''}.png`} alt="search-img" />
          </Link>
          {/* 
          <Link to={`${langPrefix}/`} className="Discover-search-beta">
            <img src={`/assets/images/flowfo-text${theme === THEME_DARK ? '-darkmode' : ''}.svg`} alt="Flowfo" />
          </Link>
         */}
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, formikBag) => this.handleSubmit(values, formikBag)}
        >
          {({ setFieldValue }) => (
            <Form autoComplete="false" className="Discover-search-form">
              <div className="Discover-search-block1">
                <div className="Discover-search-lookingFor">
                  <Field
                    name="search"
                    type="search"
                    label={t('input-search')}
                    placeholder={t('input-search-placeholder')}
                    autoComplete="false"
                    component={Input}
                    append={
                      <Button
                        variant="outline-neutralSubtle"
                        type="button"
                        className="Discover-search-btn"
                        onClick={() => setFieldValue('search', '')}
                      >
                        <CommunSVGs.Close width="20" height="20" />
                      </Button>
                    }
                  />
                </div>
                <div className="Discover-search-submit">
                  <LoadingButton
                    className="flowfont-medium rounded-pill"
                    label={t('search-button')}
                    isFetching={artworksIsFetching}
                    noMobileLabel
                  />
                </div>
              </div>
              <div className="Discover-search-block2">
                {_isEmpty(languagesListValues) || _isEmpty(categoriesListValues) ? (
                  <LoadingSmall className="mx-auto" />
                ) : (
                  <>
                    <Field
                      component={Select}
                      name="categories"
                      className="Discover-search-categories"
                      placeholder={t('input-categories-placeholder')}
                      isMulti
                      maxItems={5}
                      options={categoriesListValues}
                    />
                    <Field
                      component={Select}
                      name="languages"
                      className="Discover-search-languages"
                      placeholder={t('input-categories-languages')}
                      isMulti
                      maxItems={5}
                      options={languagesListValues}
                    />
                  </>
                )}
              </div>

              <div className="Discover-search-orders">
                <div
                  className={`Discover-search-order ${orderBy === 'popular' ? 'flowfont-medium text-primary' : ''}`}
                  onClick={() => this.changeOrderBy('popular')}
                  role="presentation"
                >
                  {t('order-popular')}
                </div>
                <div
                  className={`Discover-search-order ${
                    !orderBy || orderBy === 'updated' ? 'flowfont-medium text-primary' : ''
                  }`}
                  onClick={() => this.changeOrderBy('updated')}
                  role="presentation"
                >
                  {t('order-updated')}
                </div>
                <div
                  className={`Discover-search-order ${orderBy === 'new' ? 'flowfont-medium text-primary' : ''}`}
                  onClick={() => this.changeOrderBy('new')}
                  role="presentation"
                >
                  {t('order-new')}
                </div>
              </div>
              <div className="Discover-search-exclusions">
                <div
                  className={`Discover-search-exclusion ${
                    _inludes(exclusions, 'adult') ? 'flowfont-medium text-primary' : ''
                  }`}
                  onClick={() => this.toggleExclusion('adult')}
                  role="presentation"
                >
                  {t('filter-adult')}
                </div>
                <div
                  className={`Discover-search-exclusion ${
                    _inludes(exclusions, 'empty') ? 'flowfont-medium text-primary' : ''
                  }`}
                  onClick={() => this.toggleExclusion('empty')}
                  role="presentation"
                >
                  {t('filter-empty')}
                </div>
                <div
                  className={`Discover-search-exclusion ${
                    _inludes(exclusions, 'favorites') ? 'flowfont-medium text-primary' : ''
                  }`}
                  onClick={() => this.toggleExclusion('favorites')}
                  role="presentation"
                >
                  {t('filter-favorites')}
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <h1 className="h6 flowfont-medium text-secondary text-nowrap mt-4 d-none d-lg-block">
          {t('result-number', { count: artworksTotal, category: category || '' })}
        </h1>
      </div>
    );
  };

  render() {
    const {
      match: {
        params: { category },
      },
      artworks,
      artworksTotal,
      userProfile,
      acceptedArtworks,
      t,
    } = this.props;

    if (!artworks) {
      return <Loading fullPage />;
    }

    const hasCategoryTrad = t(`global:SEO_CATEGORY_TEXT_${category}`) !== `global:SEO_CATEGORY_TEXT_${category}`;

    return (
      <div className="Page Discover d-flex flex-column flex-lg-row-reverse justify-content-around">
        <Helmet>
          {seo.title(category ? `${category} - All the artworks - Flowfo` : 'Discover all artworks - Flowfo')}
          {seo.description(
            category
              ? `Many great artworks labeled with ${category} are hosted by Flowfo. It's time to discover them and to support their creator :)`
              : 'Find what your favorite creators have already done. Discover new amazing worlds, characters and stories. Sort all the artworks by language and categories.',
          )}
          {seo.canonical(category ? `/discover/${category}` : '/discover')}
        </Helmet>
        {!artworks && <Loading fullPage />}
        {artworks && (
          <>
            {this.renderSearch()}
            <div className="Page-content">
              {artworks.length === 0 && (
                <div className="Page Page-full">
                  <div className="TitleHeader-title flowfont-title text-primary text-center pt-4">
                    {t('result-empty')}
                  </div>
                </div>
              )}
              {artworks.length > 0 && (
                <InfiniteScroll
                  pageStart={1}
                  loadMore={this.loadMore}
                  hasMore={artworks.length < artworksTotal}
                  loader={<Loading key={0} />}
                  initialLoad={false}
                  className="Discover-wrapper"
                >
                  <ArtworkCardList
                    list={artworks}
                    userProfile={userProfile}
                    acceptedArtworks={acceptedArtworks}
                    mode="discover"
                    onUpdate={this.handleUpdate}
                  />
                </InfiniteScroll>
              )}
              {hasCategoryTrad && !acl.isConnected(userProfile) && (
                <div className="Discover-category">
                  <h2 className="h5">{t(`seo-category-title-${category || 'all'}`)}</h2>
                  <p>{t(`seo-category-text-${category || 'all'}`)}</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: state.user.privateProfile,
  acceptedArtworks: state.user.acceptedArtworks,
  categoriesList: state.taxonomy.categories,
  languagesList: state.taxonomy.languages,
  artworks: state.artwork.allArtworks,
  artworksTotal: state.artwork.allArtworksTotal,
  artworksLast: state.artwork.allArtworksLast,
  artworksIsFetching: _get(state.artwork, 'isFetching.allArtworks'),
});

const mapDispatchToProps = dispatch => ({
  getCategoriesAction: () => dispatch(getCategories()),
  getLanguagesAction: () => dispatch(getLanguages()),
  getAllArtworksAction: (search, page, orderBy, languages, categories, exclusions) =>
    dispatch(getAllArtworks(search, page, orderBy, languages, categories, exclusions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('discover')(withTheme(Discover)));

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import artworkUtils from 'tools/artwork';

import CommunSVGs from 'svgs/commun';

type Props = {
  artwork: Object,
  className: String,
};

const ArtworkInfo = ({ artwork, className }: Props) => {
  const { t } = useTranslation('artwork');
  return (
    <div className={`${className || ''}`}>
      <div className="Artwork-description flowfont-regular mt-0 mt-lg-2">
        <p className="Artwork-description-text mb-0">{artwork.description || t('global:default-description')}</p>
        {artwork.categories && (
          <p className="Artwork-categories my-0 my-lg-2">
            {artwork.categories.map(cat => (
              <Link key={cat.name} to={`/discover/${cat.name}`} className="fs-1 text-neutralSubtle font-italic mr-1">
                #{cat.name}
              </Link>
            ))}
          </p>
        )}
        <div>
          <span className="text-neutralSubtle flowfont-medium mr-2">
            {artworkUtils.formatCount(artwork.totalFollowers)}{' '}
            <CommunSVGs.FavoritesFull width="16" fill="var(--neutralSubtle)" transform="translate(0,-2)" />
          </span>
          {!!artwork.artworkReadCountAvg && (
            <span className="text-neutralSubtle flowfont-medium mr-2">
              <OverlayTrigger
                key="readfull"
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 500 }}
                overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-readers')}</Tooltip>}
              >
                <span className="d-inline-block">
                  {artworkUtils.formatCount(artwork.artworkReadCountAvg)}{' '}
                  <CommunSVGs.Readfull width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                </span>
              </OverlayTrigger>
            </span>
          )}
          {!!artwork.artworkFeedbackCount && (
            <span className="text-neutralSubtle flowfont-medium mr-2">
              <OverlayTrigger
                key="readfull"
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 500 }}
                overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-reactions')}</Tooltip>}
              >
                <span className="d-inline-block">
                  {artworkUtils.formatCount(artwork.artworkFeedbackCount)}{' '}
                  <CommunSVGs.NiceWork width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                </span>
              </OverlayTrigger>
            </span>
          )}
          {!!artwork.commentCount && (
            <span className="text-neutralSubtle flowfont-medium">
              <OverlayTrigger
                key="readfull"
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 500 }}
                overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-comments')}</Tooltip>}
              >
                <span className="d-inline-block">
                  {artworkUtils.formatCount(artwork.commentCount)}{' '}
                  <CommunSVGs.Bubble width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                </span>
              </OverlayTrigger>
            </span>
          )}
        </div>
        <div className="Artwork-tagsWrapper">
          <div className="Artwork-tags">
            <div className="Artwork-tag">
              <CommunSVGs.Lang fill="var(--neutralSubtle)" />
              <span className="text-uppercase text-neutralSubtle flowfont-bold fs-09">
                {artwork.language ? artwork.language.name : 'N/A'}
              </span>
            </div>

            <div className="Artwork-tag">
              {artwork.isAdult ? <CommunSVGs.NSFW /> : ''}
              <span className="text-uppercase text-neutral flowfont-bold">{artwork.isAdult ? 'NSFW' : ''}</span>
            </div>

            <div className="Artwork-tag">
              <CommunSVGs.Tag fill="var(--neutralSubtle)" />
              <span className="text-neutralSubtle text-uppercase flowfont-bold fs-09">
                {artwork.mediaType || 'Other'}
              </span>
            </div>

            {artwork.website && (
              <div className="Artwork-tag">
                <CommunSVGs.WebPage fill="var(--primary)" />
                <a
                  href={(artwork.website.includes('http') ? '' : 'http://') + artwork.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary text-uppercase flowfont-bold fs-09"
                >
                  {t('Website')}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtworkInfo;

/* eslint-disable react/no-danger */
import loadable from '@loadable/component';

import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import acl from 'tools/acl';

import { LoadingSmall } from 'components/Loading';

import CommunSVGs from 'svgs/commun';

const FlowFolder = loadable(() => import('components/Folder/FlowFolder'), { fallback: <LoadingSmall /> });
const PackFolder = loadable(() => import('components/Folder/PackFolder'), { fallback: <LoadingSmall /> });

type Props = {
  artwork: Object,
  userProfile: Object,
  onStartFlow: Function,
};

const ArtworkFlows = ({ artwork, userProfile, onStartFlow }: Props) => {
  const { t } = useTranslation('artwork');
  const isUserArtwork = acl.isAuthor(userProfile, artwork);

  if (artwork.isFlow || artwork.packs.length) {
    return (
      <>
        {artwork.isFlow && <FlowFolder flow={artwork} isFull userProfile={userProfile} />}

        {/* {artwork.isFlow && artwork.packs && <hr className="Artwork-separator" />} */}

        {artwork.packs && (
          <div className="Artwork-packs">
            {artwork.packs.map(pack => (
              <PackFolder
                key={pack.id}
                className="Artwork-pack"
                pack={pack}
                isFull
                artwork={artwork}
                userProfile={userProfile}
              />
            ))}
          </div>
        )}

        {isUserArtwork && (
          <>
            {!artwork.isFlow && (
              <div className="text-center">
                <Button variant="link" className="Tab-button mt-4 flowfont-medium text-primary" onClick={onStartFlow}>
                  <CommunSVGs.Flow width="20px" height="20px" fill="var(--primary)" className="mr-2" />
                  {t('create-flow-user')}
                </Button>
              </div>
            )}

            <div className="text-center flowfont-medium mt-2">
              <Link to={`/pack/add/artwork/${artwork.id}`} className="Tab-link text-secondary">
                <CommunSVGs.Pack width="20px" height="20px" fill="var(--secondary)" className="mr-2" />
                {t('create-pack-user')}
              </Link>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <div className="text-center">
      <div className="h6 flowfont-title text-secondary fs-125">{t('global:waiting-content')}</div>
      {isUserArtwork && (
        <div className="flowfont-thin">
          <div dangerouslySetInnerHTML={{ __html: t('share-title') }} />
          <div>
            <Button variant="link" className="Tab-button mt-4 flowfont-medium text-primary" onClick={onStartFlow}>
              {t('create-flow-other')}
            </Button>
          </div>
          <Link to={`/pack/add/artwork/${artwork.id}`} className="Tab-link flowfont-medium text-secondary">
            {t('create-pack-other')}
          </Link>
        </div>
      )}
    </div>
  );
};

export default memo(ArtworkFlows);

import moment from 'moment';

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CURRENCY } from 'constants/Constants';

import { getBillings } from 'actions/UserActions';

import Cart from 'components/Cart';
import Loading from 'components/Loading';

import './Billings.scss';

export default function Billings() {
  const dispatch = useDispatch();
  const { t } = useTranslation('billings');

  const billings = useSelector(state => state.user.billings);

  useEffect(() => {
    dispatch(getBillings());
  }, [dispatch]);

  const getInvoiceNumber = billing => {
    return `${billing.id}`.padStart(10, '0');
  };

  const totalPrice = billing => {
    const total = billing.netPrice + billing.vatPrice;
    if (total > 0) {
      return -total;
    }
    return '0.00';
  };

  return (
    <div className="Billings">
      <div className="Billings-info">{t('info-text')}</div>
      {!billings && <Loading fullPage />}
      {billings && billings.length === 0 && <div className="mt-4 flowfont-medium">Empty</div>}
      {billings &&
        billings.map(billing => (
          <div key={billing.id} className="Billings-main">
            <div className="d-flex Billings-cart-total">
              <div className="Billings-cart-totaldate">{moment(billing.createdAt).format('DD/MM/YYYY - HH:mm')}</div>
              {/* <div className="Billings-cart-title">Purchase</div> */}
              <div className="Billings-cart-totalprice">
                {totalPrice(billing)} {CURRENCY}
              </div>
            </div>
            <div className="Billings-cart-invoice">
              {t('order-number', { invoiceNumber: getInvoiceNumber(billing) })}
            </div>
            <Cart cart={billing} withoutTotal readOnly className="Billings-cart" />
          </div>
        ))}
    </div>
  );
}

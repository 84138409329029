import React from 'react';

import { useMediaQuery } from 'react-responsive';

import _isEmpty from 'lodash/isEmpty';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { MEDIA_SM } from 'constants/Constants';

import Loading from 'components/Loading';

import ArtworkCard from './ArtworkCard';
import './ArtworkCardList.scss';
import ArtworkCover from './ArtworkCover';

type Props = {
  list: Array,
  userProfile: Object,
  acceptedArtworks: [],
  mode: String,
  onUpdate: Function,
};

function ArtworkCardList({ list, userProfile, acceptedArtworks, mode, onUpdate }: Props) {
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_SM,
  });
  if (!list || !list[0] || _isEmpty(list[0].author)) {
    return <Loading />;
  }

  const dualLines = [];
  if (!isMobile) {
    for (let i = 0; i < list.length; i += 4) {
      dualLines.push(list.slice(i, i + 4));
    }
  }
  if (mode === 'discover') {
    return (
      <div className="ArtworkCardList">
        <Container>
          {!isMobile &&
            dualLines.map(dualLine => (
              <div key={JSON.stringify(dualLine[0])}>
                <Row className="ArtworkCardList-layout3">
                  <Col xs={4} className="ArtworkCardList-layout3-col">
                    <ArtworkCover
                      artwork={dualLine[0]}
                      userProfile={userProfile}
                      acceptedArtworks={acceptedArtworks}
                      layout={3}
                      onUpdate={onUpdate}
                    />
                  </Col>
                  {dualLine[1] && (
                    <Col xs={4} className="ArtworkCardList-layout3-col">
                      <ArtworkCover
                        artwork={dualLine[1]}
                        userProfile={userProfile}
                        acceptedArtworks={acceptedArtworks}
                        layout={3}
                        onUpdate={onUpdate}
                      />
                    </Col>
                  )}
                  {dualLine[2] && (
                    <Col xs={4} className="ArtworkCardList-layout3-col">
                      <ArtworkCover artwork={dualLine[2]} userProfile={userProfile} layout={3} onUpdate={onUpdate} />
                    </Col>
                  )}
                </Row>
                {dualLine[3] && (
                  <Row className="ArtworkCardList-layout1">
                    <Col xs={12} className="ArtworkCardList-layout1-col">
                      <ArtworkCover
                        artwork={dualLine[3]}
                        userProfile={userProfile}
                        acceptedArtworks={acceptedArtworks}
                        layout={3}
                        fullWidth
                        onUpdate={onUpdate}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            ))}
          {isMobile && (
            <Row className="ArtworkCardList-layout2">
              {list.map(artwork => (
                <Col xs={6} key={JSON.stringify(artwork)} className="ArtworkCardList-layout2-col">
                  <ArtworkCover
                    artwork={artwork}
                    userProfile={userProfile}
                    acceptedArtworks={acceptedArtworks}
                    onUpdate={onUpdate}
                    layout={2}
                  />
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
    );
  }

  return (
    <div className="ArtworkCardList">
      {list.map(item => (
        <ArtworkCard artwork={item} userProfile={userProfile} key={JSON.stringify(item)} />
      ))}
    </div>
  );
}

export default ArtworkCardList;

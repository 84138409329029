/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable react/forbid-prop-types */

/* eslint-disable react/require-default-props */

/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import requiredForA11y from 'prop-types-extra/lib/isRequiredForA11y';
import { useUncontrolled } from 'uncontrollable';

import React from 'react';

import { forEach, map } from 'react-bootstrap/ElementChildren';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';

import './CustomTabs.scss';

/*
 * Override Boostrap Tabs implementation in order to have a real href value in renderTab function (SEO friendly)
 * show https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Tabs.js
 */

const propTypes = {
  /**
   * Mark the Tab with a matching `eventKey` as active.
   *
   * @controllable onSelect
   */
  activeKey: PropTypes.any,
  /** The default active key that is selected on start */
  defaultActiveKey: PropTypes.any,

  /**
   * Navigation style
   *
   * @type {('tabs'| 'pills')}
   */
  variant: PropTypes.string,

  /**
   * Sets a default animation strategy for all children `<TabPane>`s.
   * Defaults to `<Fade>` animation, else use `false` to disable or a
   * react-transition-group `<Transition/>` component.
   *
   * @type {Transition | false}
   * @default {Fade}
   */
  transition: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.elementType]),

  /**
   * HTML id attribute, required if no `generateChildId` prop
   * is specified.
   *
   * @type {string}
   */
  id: requiredForA11y(PropTypes.string),

  /**
   * Callback fired when a Tab is selected.
   *
   * ```js
   * function (
   *   Any eventKey,
   *   SyntheticEvent event?
   * )
   * ```
   *
   * @controllable activeKey
   */
  onSelect: PropTypes.func,

  /**
   * Wait until the first "enter" transition to mount tabs (add them to the DOM)
   */
  mountOnEnter: PropTypes.bool,

  /**
   * Unmount tabs (remove it from the DOM) when it is no longer visible
   */
  unmountOnExit: PropTypes.bool,
};

const defaultProps = {
  variant: 'tabs',
  mountOnEnter: false,
  unmountOnExit: false,
};

function getDefaultActiveKey(children) {
  let defaultActiveKey;
  forEach(children, child => {
    if (defaultActiveKey == null) {
      defaultActiveKey = child.props.eventKey;
    }
  });

  return defaultActiveKey;
}

function renderTab(child) {
  const { title, eventKey, disabled, tabClassName, href, id } = child.props;
  if (title == null) {
    return null;
  }

  return (
    <NavItem
      as={NavLink}
      eventKey={eventKey}
      href={href}
      disabled={disabled}
      id={id}
      className={tabClassName}
      onClick={e => e.preventDefault()}
    >
      {title}
    </NavItem>
  );
}

const CustomTabs = React.forwardRef((props, ref) => {
  const {
    id,
    onSelect,
    transition,
    mountOnEnter,
    unmountOnExit,
    children,
    activeKey = getDefaultActiveKey(children),
    ...controlledProps
  } = useUncontrolled(props, {
    activeKey: 'onSelect',
  });

  return (
    <TabContainer
      ref={ref}
      id={id}
      activeKey={activeKey}
      onSelect={onSelect}
      transition={transition}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
    >
      <Nav {...controlledProps} className="Tabs" role="tablist" as="nav">
        {map(children, renderTab)}
      </Nav>

      <TabContent>
        {map(children, child => {
          const childProps = { ...child.props };

          delete childProps.title;
          delete childProps.disabled;
          delete childProps.tabClassName;

          return <TabPane {...childProps} />;
        })}
      </TabContent>
    </TabContainer>
  );
});

CustomTabs.propTypes = propTypes;
CustomTabs.defaultProps = defaultProps;
CustomTabs.displayName = 'Tabs';

export default CustomTabs;

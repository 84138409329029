import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import { acceptArtwork } from 'actions/UserActions';

import ArtworkDisclaimer from 'components/Artwork/ArtworkDisclaimer';
import ArtworkFavorites from 'components/Artwork/ArtworkFavorites';
import Avatar from 'components/Avatar';
import Cover from 'components/Cover';
import Loading from 'components/Loading';

import CommunSVGs from 'svgs/commun';

import './ArtworkCover.scss';

type Props = {
  artwork: Object,
  layout: Number,
  fullWidth: Boolean,
  userProfile: Object,
  acceptedArtworks: [],
  onUpdate: Function,
};

const ArtworkCover = ({ artwork, layout, fullWidth, userProfile, acceptedArtworks, onUpdate }: Props) => {
  const { t } = useTranslation('artworkcardlist');
  const dispatch = useDispatch();
  const [showDisclaimer, setShowDisclaimer] = React.useState(false);

  const username = artwork.author.username || userProfile.username;

  const handleCatClick = cat => () => {
    if (onUpdate) {
      onUpdate({ type: 'cat', value: cat });
    }
  };

  const handleArtworkClick = (anchor, validated) => e => {
    if (e) {
      if (e.ctrlKey) {
        return;
      }
      e.preventDefault();
    }
    const nsfw = artworkUtils.isDisclaimerNeeded(userProfile, artwork, acceptedArtworks);
    if (nsfw && !validated) {
      setShowDisclaimer(true);
    } else {
      dispatch(acceptArtwork(artwork.id, artwork.uniqueName, username));
    }
  };

  const renderCoverOverlay = () => {
    return (
      <div className="ArtworkCover-overlay">
        <div className="ArtworkCover-text">
          <a
            href={`/artwork/${username}/${artwork.uniqueName}`}
            className="ArtworkCover-description cursor"
            onClick={handleArtworkClick()}
          >
            {artwork.description || t('global:default-description')}
          </a>
          {artwork.categories && (
            <div className="ArtworkCover-categories">
              {artwork.categories.map(cat => (
                <span
                  key={cat.name}
                  className="ArtworkCover-categorie"
                  onClick={handleCatClick(cat.name)}
                  role="presentation"
                >
                  #{cat.name}
                </span>
              ))}
            </div>
          )}
          {/* <a
            href={`/artwork/${username}/${artwork.uniqueName}`}
            onClick={handleArtworkClick()}
            className="ArtworkCover-text-block cursor"
          /> */}
        </div>
        <a
          href={`/artwork/${username}/${artwork.uniqueName}`}
          className="ArtworkCover-Footer cursor"
          onClick={handleArtworkClick()}
        >
          <div className="ArtworkCover-Favorites">
            <ArtworkFavorites artwork={artwork} userId={_get(userProfile, 'id')} />
          </div>
          <div className="ArtworkCover-tag">
            {artwork.isAdult ? <CommunSVGs.NSFW /> : ''}
            {artwork.isAdult ? <span className="ArtworkCover-tag-nsfw text-uppercase flowfont-medium">NSFW</span> : ''}
          </div>
        </a>
        {/* </Link> */}
      </div>
    );
  };

  const renderBannerOverlay = () => {
    const profile = acl.isAuthor(userProfile, artwork) ? userProfile : artwork.author;

    return (
      <div className="ArtworkCover-banner-overlay">
        <a
          href={`/artwork/${username}/${artwork.uniqueName}`}
          className="ArtworkCover-banner-cover-link cursor"
          onClick={handleArtworkClick()}
        >
          <Cover
            src={artworkUtils.getCoverUrl(artwork, 110)}
            // srcLq={artworkUtils.getCoverUrl(artwork, 110, null, null, 'LQPI')}
            alt={artwork.name}
            className="ArtworkCover-banner-cover"
          />
        </a>
        <div className="ArtworkCover-banner-text">
          <div className="ArtworkCover-banner-topArea">
            <div className="ArtworkCover-banner-cathegories">
              {artwork.categories && (
                <div className="ArtworkCover-banner-categories">
                  {artwork.categories.map(cat => (
                    <span
                      key={cat.name}
                      className="ArtworkCover-banner-categorie"
                      onClick={handleCatClick(cat.name)}
                      role="presentation"
                    >
                      #{cat.name}
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div className="ArtworkCover-banner-titleArea">
              <div className="ArtworkCover-banner-author">
                <Link to={`/profile/${username}#artworks`}>
                  <Avatar
                    src={artworkUtils.getAvatarUrl(_get(profile, 'avatarFile'), 22)}
                    alt={username}
                    className="ArtworkCover-banner-avatar border border-neutralSubtle"
                  />
                  <div className="ArtworkCover-banner-username h5">{username}</div>
                  <div className="clearfix" />
                </Link>
              </div>

              <div className="ArtworkCover-banner-subtitle">
                <div
                  className="ArtworkCover-banner-title flowfont-title cursor"
                  onClick={handleArtworkClick()}
                  role="presentation"
                >
                  <a href={`/artwork/${username}/${artwork.uniqueName}`}>{artwork.name}</a>
                </div>
                <ArtworkFavorites artwork={artwork} userId={_get(userProfile, 'id')} />
              </div>
            </div>
          </div>
          <div className="ArtworkCover-banner-bottomArea">
            <div className="ArtworkCover-banner-tagsArea">
              <div className="ArtworkCover-banner-tag">
                {artwork.isAdult ? <CommunSVGs.NSFW /> : ''}
                {artwork.isAdult ? <span className="ArtworkCover-banner-tag-nsfw text-uppercase">NSFW</span> : ''}
              </div>
              <div className="ArtworkCover-banner-tag">
                <CommunSVGs.Lang />
                <span className="text-uppercase">{artwork.language ? artwork.language.name : 'N/A'}</span>
              </div>
              <div className="ArtworkCover-banner-tag">
                <CommunSVGs.Tag fill="#BBB" />
                <span className="text-uppercase">{artwork.mediaType || 'Other'}</span>
              </div>
              {artwork.website && (
                <div className="ArtworkCover-banner-tag ArtworkCover-website">
                  <CommunSVGs.WebPage fill="#fff" />
                  <a
                    href={(artwork.website.includes('http') ? '' : 'http://') + artwork.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-uppercase"
                  >
                    web
                  </a>
                </div>
              )}
            </div>

            <div className="ArtworkCover-banner-description">
              {artwork.description || t('global.default-description')}
            </div>
          </div>
        </div>
        <div className="ArtworkCover-banner-gradient" />
      </div>
    );
  };

  if (_isEmpty(artwork)) {
    return <Loading />;
  }

  return (
    <div className={`ArtworkCover${fullWidth ? ' ArtworkCover-banner' : ''}`} key={JSON.stringify(artwork)}>
      {fullWidth && (
        <div className="ArtworkCover-banner-hover">
          <img
            // src={artworkUtils.getBannerUrl(artwork, 526, 167, null, 'LQPI')}
            src={artworkUtils.getBannerUrl(false, 526, 167, null, 50)}
            data-src={artworkUtils.getBannerUrl(artwork, 526, 167)}
            alt={artwork.name}
            className="Cover-image Banner-image lazyload"
          />
          <div className="ArtworkCover-banner-overlay">{renderBannerOverlay()}</div>
        </div>
      )}
      {!fullWidth && (
        <Cover
          src={artworkUtils.getCoverUrl(artwork, layout === 3 ? 167 : 255)}
          // srcLq={artworkUtils.getCoverUrl(artwork, layout === 3 ? 167 : 255, null, null, 'LQPI')}
          alt={artwork.name}
          className="Banner-cover"
          overlayTrigger="hover"
          overlay={renderCoverOverlay()}
        />
      )}
      {showDisclaimer && (
        <ArtworkDisclaimer
          artwork={artwork}
          userProfile={userProfile}
          closeLabel="Go back"
          onAccept={handleArtworkClick(null, true)}
          onClose={() => setShowDisclaimer(false)}
        />
      )}
    </div>
  );
};

export default React.memo(ArtworkCover);

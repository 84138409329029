import moment from 'moment';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';

import artworkUtils from 'tools/artwork';

import userService from 'services/user';

import Avatar from 'components/Avatar';

import CommunSVGs from 'svgs/commun';
import DecorationSVGs from 'svgs/decoration';
import SocialSVGs from 'svgs/social';

type Props = {
  profile: Object,
  isMe: Boolean,
  onGoBack: Function,
};

const renderSocialIcon = social => {
  const Icon = SocialSVGs?.[_capitalize(social.name)];
  if (!Icon) {
    return null;
  }
  return <Icon fill="var(--primary)" width="30" height="30" className="User-icon" />;
};

const UserProfile = ({ profile, isMe, onGoBack }: Props) => {
  const { t, i18n } = useTranslation('user');

  const socialLinks = userService.socialLinks.map(
    social =>
      profile[social.key] && (
        <div className="m-1" key={social.key}>
          {profile[social.key] && (
            <a
              href={(profile[social.key].includes('http') ? '' : 'http://') + profile[social.key]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {renderSocialIcon(social)}
            </a>
          )}
        </div>
      ),
  );

  return (
    <div className="Page-card">
      <div className="position-relative">
        <div className="User-iconBg">
          <DecorationSVGs.AvatarShadow width="450px" height="450px" fill="var(--distant)" />
        </div>
        <div className="User" style={{ zIndex: 5 }}>
          <div className="d-flex flex-row flex-lg-column justify-content-center justify-content-lg-start">
            {isMe && (
              <div className="User-actions">
                <Link to="/profile/settings">
                  <CommunSVGs.Cog width="24px" height="24px" fill="var(--primary)" />
                </Link>
              </div>
            )}
            <div className="User-back" onClick={onGoBack} role="presentation">
              <CommunSVGs.ArrowLeft width="25px" height="25px" fill="#EDEDED" />
            </div>
            <Avatar
              src={artworkUtils.getAvatarUrl(_get(profile, 'avatarFile'), 180)}
              alt="User"
              className="User-avatar border border-neutralSubtle"
            />
            <div className="User-profile">
              <div className="User-personalInfo d-none d-md-block">
                <h1 className="User-username h4 flowfont-title text-primary">{profile.username}</h1>
              </div>
              <p className="User-registerDate flowfont-regular text-neutralSubtle text-truncate">
                {t('profile-registered', {
                  date: moment(profile.createdAt).locale(i18n.language).format('MMMM YYYY'),
                })}
              </p>
              <div className="User-social d-none d-md-flex flex-row justify-content-center">{socialLinks}</div>
              <div className="User-extra d-flex justify-content-center mt-3 text-secondary flowfont-bold">
                <div className="d-flex flex-column flex-md-row flex-lg-column flex-nowrap text-left mx-auto">
                  <div className="d-block mr-2 text-truncate">
                    <CommunSVGs.Localisation height="20" fill="var(--secondary)" />{' '}
                    {profile.localisation || t('localisation-default-text')}
                  </div>
                  <div className="d-block mr-2 text-truncate">
                    <CommunSVGs.LetterI height="16" fill="var(--secondary)" />{' '}
                    {profile.gender || t('gender-default-text')}
                  </div>
                  {/* <div className="d-block mr-2 text-truncate">
                  <img src="/assets/icons/spokenlanguages.svg" alt="" /> {profile?.language?.length > 0 || 'EN, FR'}
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="User-profile-mobile d-block d-md-none">
            <div className="User-personalInfo">
              <h1 className="User-username h4 flowfont-title text-primary">{profile.username}</h1>
            </div>
            <div className="User-social d-flex flex-row justify-content-center">{socialLinks}</div>
          </div>
        </div>
        <div className="User-iconBg">
          <DecorationSVGs.AvatarShadow width="450px" height="450px" fill="#fff" />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

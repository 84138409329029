import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import _isEmpty from 'lodash/isEmpty';

import Button from 'react-bootstrap/Button';

import ArtworkCardList from 'components/ArtworkCardList';

import CommunSVGs from 'svgs/commun';

type Props = {
  artworks: [],
  isMe: Boolean,
  userProfile: Object,
};

const UserContent = ({ artworks, isMe, userProfile }: Props) => {
  const { t } = useTranslation('user');

  if (!_isEmpty(artworks)) {
    return (
      <>
        <ArtworkCardList list={artworks} userProfile={userProfile} />
        {isMe && (
          <div className="text-center">
            <div className="flowfont-regular fs-09">
              <Button variant="primary" className="Tab-button mt-4 white flowfont-bold fs-08 rounded-pill">
                <Link to="/artwork/add" className="white flowfont-medium fs-09 px-3">
                  <CommunSVGs.AddPage width="20px" height="20px" fill="#fff" className="mr-2" />
                  {t('content-create-button')}
                </Link>
              </Button>
            </div>
            <div className="flowfont-thin fs-100 mt-3">
              <p>
                <a href="https://vimeo.com/flowfo" target="_blank" rel="noopener noreferrer">
                  {t('content-me-tuto-link')}
                </a>
              </p>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="text-center">
      <div className="h6 flowfont-title text-secondary fs-125">{t('No artwork created yet')}</div>
      <div className="flowfont-thin fs-100">
        <div dangerouslySetInnerHTML={{ __html: t('content-other-text') }} />
        <p>
          <a href="https://vimeo.com/flowfo" target="_blank" rel="noopener noreferrer">
            {t('To know more, watch our tuto!')}
          </a>
        </p>
        {isMe && (
          <Button variant="primary" className="Tab-button mt-4 white fs-08 rounded-pill">
            <Link to="/artwork/add" className="white flowfont-regular fs-09 px-3">
              <CommunSVGs.AddPage width="20px" height="20px" fill="#fff" className="mr-2" />
              {t('content-other-tuto-link')}
            </Link>
          </Button>
        )}
        <br />
        <br />
        <Link to="/discover" className="Tab-link text-secondary flowfont-medium">
          <CommunSVGs.Discover width="20px" height="20px" fill="var(--secondary)" className="mr-2" />
          {t('content-other-discover-link')}
        </Link>
      </div>
    </div>
  );
};

export default UserContent;

import React from 'react';

import { Link } from 'react-router-dom';

import _get from 'lodash/get';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import ArtworkFavorites from 'components/Artwork/ArtworkFavorites';
import ArtworkHeaderActions from 'components/Artwork/ArtworkHeaderActions';
import Avatar from 'components/Avatar';
import Cover from 'components/Cover';

import CommunSVGs from 'svgs/commun';

import './Banner.scss';

type Props = {
  userProfile: Object,
  className: string,
  artwork: Object,
  isArtworkPage: Boolean,
  isGalleryPage: Boolean,
  onGoBack: Function,
};

const Banner = ({ artwork, isArtworkPage, isGalleryPage, userProfile, className, onGoBack }: Props) => {
  const profile = acl.isAuthor(userProfile, artwork) ? userProfile : artwork.author;
  const username = _get(artwork, 'author.username') || _get(userProfile, 'username');

  return (
    <div className={`${className} ${isArtworkPage ? '' : 'is-card'} Banner`}>
      <div className="Banner-images">
        <Link to={`/artwork/${username}/${artwork.uniqueName}`} className="Banner-background">
          <img src={artworkUtils.getBannerUrl(artwork, 530, 177)} alt={artwork.name} className="Banner-image" />
        </Link>
        {!isArtworkPage && !isGalleryPage && (
          <div className="Banner-actions">
            <ArtworkHeaderActions artwork={artwork} />
          </div>
        )}
        <div className="Banner-gradient" />
      </div>
      {isArtworkPage && (
        <div className="Artwork-back" onClick={onGoBack} role="presentation">
          <CommunSVGs.ArrowLeft width="25px" height="25px" fill="#EDEDED" />
        </div>
      )}

      <Link to={`/artwork/${username}/${artwork.uniqueName}`} className="Banner-cover-wrapper">
        <Cover
          src={artworkUtils.getCoverUrl(artwork, 129)}
          alt={artwork.name}
          className={`${isArtworkPage ? 'd-block d-lg-none ' : ''}Banner-cover`}
        />
      </Link>

      <div className={`${isArtworkPage ? 'd-block d-lg-none' : ''}`}>
        <div className="Banner-firstLine">
          <h2 className="Banner-title flowfont-title text-primary">
            <Link to={`/artwork/${username}/${artwork.uniqueName}`}>{artwork.name}</Link>
          </h2>
          <div className="Banner-favZone">
            <ArtworkFavorites artwork={artwork} userId={_get(userProfile, 'id')} />
          </div>
        </div>
        <div className="Banner-subtitle">
          <div className={`Banner-author${isArtworkPage ? ' d-block d-lg-none' : ''}`}>
            <Link to={`/profile/${username}#artworks`}>
              <Avatar
                src={artworkUtils.getAvatarUrl(_get(profile, 'avatarFile'), 30, 30)}
                alt={username}
                className="Banner-avatar"
              />
              <div className="Banner-username h5">{username}</div>
              <div className="clearfix" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Banner);

import React from 'react';

import { useDispatch } from 'react-redux';

import _get from 'lodash/get';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import { showNotification } from 'actions/NotificationsActions';

import Cover from 'components/Cover';

import CommunSVGs from 'svgs/commun';

type Props = {
  suggestion: Object,
  shouldUseBanner: Boolean,
  userProfile: Object,
  onNotInterested: Function,
};

const ArtworkSuggestion = ({ suggestion, shouldUseBanner, userProfile, onNotInterested }: Props) => {
  const [isAnimating, setAnimating] = React.useState(false);

  const dispatch = useDispatch();

  const handleNotInterested = e => {
    e.preventDefault();
    if (acl.isConnected(userProfile)) {
      onNotInterested(suggestion.id);
      setAnimating(true);
    } else {
      dispatch(
        showNotification({
          level: 'info',
          title: 'Log in before doing this action.',
        }),
      );
    }
  };

  const renderNotInterested = () => {
    return (
      <a href={`/artwork/${_get(suggestion, 'author.username')}/${suggestion.uniqueName}`} className="w-100">
        <div className="Artwork-suggestions-close" onClick={handleNotInterested} role="presentation">
          <CommunSVGs.CloseSuggestion width="24" height="24" />
        </div>
      </a>
    );
  };

  return (
    <div
      className={`Artwork-suggestions-cover${shouldUseBanner ? ' banner' : ''} mb-2${
        shouldUseBanner ? '' : ' mb-xl-0'
      } mr-2 mr-xl-0 animated${isAnimating ? ' zoomOut' : ''}`}
    >
      {!shouldUseBanner && (
        <Cover
          className="Banner-cover"
          src={artworkUtils.getCoverUrl(suggestion, 167)}
          alt={suggestion.name}
          overlayTrigger="hover"
          overlay={renderNotInterested(suggestion)}
        />
      )}
      {shouldUseBanner && (
        <Cover
          className="Cover-image Banner-image"
          srcLq={artworkUtils.getBannerUrl(false, 263, 84, null, 50)}
          src={artworkUtils.getBannerUrl(suggestion, 263, 84)}
          alt={suggestion.name}
          overlayTrigger="hover"
          overlay={renderNotInterested(suggestion)}
        />
      )}
    </div>
  );
};

export default ArtworkSuggestion;

import loadable from '@loadable/component';

import React from 'react';

import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch, useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';
import seo from 'tools/seo';

import { getPrivateProfile, getPublicProfile, getUserArtworks } from 'actions/UserActions';

import { CustomTabs, CustomTab } from 'components/CustomTabs';
import Loading from 'components/Loading';

import '../Page.scss';
import './User.scss';
import UserContent from './partials/UserContent';
import UserProfile from './partials/UserProfile';

const UserAbout = loadable(() => import('./partials/UserAbout'), { fallback: <Loading /> });
const NoMatch404 = loadable(() => import('pages/NoMatch404'), { fallback: <Loading fullPage /> });

const User = () => {
  // store selectors
  const userProfile = useSelector(state => state.user.privateProfile);
  const userArtworks = useSelector(state => state.user.artworks);
  const publicProfile = useSelector(state => state.user.publicProfile);
  const publicArtworks = useSelector(state => state.user.publicArtworks);
  const error = useSelector(state => state.user.error);

  const { t } = useTranslation('user');
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  // actions
  const dispatch = useDispatch();

  if (typeof document === 'undefined' && error?.publicProfile !== 404) {
    batch(() => {
      dispatch(getPublicProfile(params.id)); // SSR
      dispatch(getUserArtworks(params.id));
    });
  }

  React.useEffect(() => {
    const isMe = params.id === userProfile?.username;
    const isConnected = !!acl.getToken();
    if (isConnected && isMe) {
      dispatch(getPrivateProfile());
    } else if (!isConnected && isMe) {
      history.push('/'); // logout
    } else if (!isMe && (!publicProfile || publicProfile.username !== params.id)) {
      dispatch(getPublicProfile(params.id));
    }
    dispatch(getUserArtworks(isMe ? 'me' : params.id));
  }, [params.id, dispatch]);

  const defaultActiveKey = location.hash.substr(1) || 'artworks';

  const isMe = params.id === userProfile?.username;
  if (!isMe && error?.publicProfile === 404) {
    return <NoMatch404 location={location} history={history} />;
  }
  if (!isMe && !publicProfile) {
    return <Loading isFullPage />;
  }

  let profile;
  let profileArtworks;
  if (isMe) {
    profile = userProfile;
    profileArtworks = userArtworks;
  } else {
    profile = publicProfile;
    profileArtworks = publicArtworks;
  }

  return (
    <div className="Page User-Page d-flex flex-column flex-lg-row-reverse justify-content-around">
      <Helmet>
        {seo.title(`${profile?.username || ''} is on Flowfo`)}
        {seo.description(`Discover the great artworks that ${profile.username} proposes on Flowfo.`)}
        {seo.image(artworkUtils.getAvatarUrl(profile?.avatarFile))}
        {seo.noIndex()}
      </Helmet>
      <UserProfile profile={profile} isMe={isMe} />
      <div className="Page-content Artwork-teaser">
        <CustomTabs id="user-tabs" defaultActiveKey={defaultActiveKey} onSelect={key => history.push(`#${key}`)}>
          <CustomTab eventKey="artworks" title={t('tab-arwork')} className="Tab">
            <UserContent artworks={profileArtworks} isMe={isMe} userProfile={userProfile} />
          </CustomTab>
          <CustomTab eventKey="about" title={t('tab-about')} className="Tab">
            <UserAbout isMe={isMe} profile={profile} />
          </CustomTab>
        </CustomTabs>
      </div>
    </div>
  );
};

export default User;

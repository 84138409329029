import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import _get from 'lodash/get';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import artworkUtils from 'tools/artwork';

import { showNotification } from 'actions/NotificationsActions';
import { addFollowedArtwork, deleteFollowedArtwork } from 'actions/UserActions';

import CommunSVGs from 'svgs/commun';

import './ArtworkFavorites.scss';

type Props = {
  artwork: Object,
  userId: number,
  isProfile: Boolean,
  className: String,
};

const ArtworkFavorites = ({ className, isProfile, userId, artwork }: Props) => {
  const { t } = useTranslation('artworkfavorites');
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [isFollowed, setIsFollowed] = React.useState(_get(artwork, 'artworkAccess.isFollower'));

  // actions
  const dispatch = useDispatch();

  React.useEffect(() => {
    setIsFollowed(_get(artwork, 'artworkAccess.isFollower'));
  }, [artwork]);

  const iconSize = isProfile ? 30 : 28;

  if (!artwork.id) {
    return null;
  }

  const toggleFavorite = () => {
    if (userId) {
      // connected
      const data = {
        userId,
        artworkId: artwork.id,
      };

      if (_get(artwork, 'artworkAccess.isSubscriber')) {
        dispatch(
          showNotification({
            level: 'info',
            title: 'Unsubscribe before doing this action.',
          }),
        );
      } else if (_get(artwork, 'artworkAccess.isUnsubscribing')) {
        dispatch(
          showNotification({
            level: 'info',
            title: 'Available next month when your subscription will stop.',
          }),
        );
      } else {
        if (_get(artwork, 'artworkAccess.isFollower')) {
          dispatch(deleteFollowedArtwork(data));
        } else {
          dispatch(addFollowedArtwork(data));
        }
        setIsFollowed(!isFollowed);
        setIsAnimating(true);
        setTimeout(() => {
          setIsAnimating(false);
        }, 500);
      }
    } else {
      dispatch(
        showNotification({
          level: 'info',
          title: 'Log in before doing this action.',
        }),
      );
    }
  };

  return (
    <div className={`ArtworkFavorites ${className || ''} ${isProfile ? 'full' : ''}`}>
      {isProfile && (
        <Button
          size="sm"
          id="FlowfoButtonFollow"
          onClick={() => toggleFavorite()}
          className="ArtworkFavorites-button-full rounded-pill flowfont-bold mb-1"
          variant="primary"
        >
          {isFollowed ? (
            <CommunSVGs.FavoritesFull width="20" height="20" fill="white" />
          ) : (
            <CommunSVGs.FavoritesEmpty width="20" height="20" fill="white" />
          )}{' '}
          {isFollowed ? t('followed') : t('follow')}
        </Button>
      )}
      {!isProfile && (
        <div className="ArtworkFavorites-wrapper favorites">
          <span className="ArtworkFavorites-total text-primary">
            {artworkUtils.formatCount(artwork.totalFollowers)}
          </span>
          <Button
            onClick={() => toggleFavorite()}
            className={`ArtworkFavorites-button animated${isAnimating ? ' rubberBand' : ''}`}
            variant="link"
          >
            {isFollowed ? (
              <CommunSVGs.FavoritesFull width={iconSize} fill="var(--primary)" />
            ) : (
              <CommunSVGs.FavoritesEmpty width={iconSize} fill="var(--primary)" />
            )}
          </Button>
        </div>
      )}
      {!isProfile && !!artwork.artworkReadCountAvg && (
        <div className="ArtworkFavorites-wrapper readfull">
          <OverlayTrigger
            key="readfull"
            placement="bottom"
            trigger={['hover', 'focus']}
            delay={{ show: 500 }}
            overlay={<Tooltip className="FolderFile-tooltipBlue">{t('readers')}</Tooltip>}
          >
            <span>
              <span className="ArtworkFavorites-total text-secondary">
                {artworkUtils.formatCount(artwork.artworkReadCountAvg)}
              </span>
              <CommunSVGs.Readfull width={iconSize} color="var(--secondary)" transform="translate(0,-2)" />
            </span>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
};

export default React.memo(ArtworkFavorites);

import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import acl from 'tools/acl';

import CommunSVGs from 'svgs/commun';

type Props = {
  artwork: Object,
  userProfile: Object,
  full: Boolean,
};

class ArtworkActions extends PureComponent<Props> {
  render() {
    const { artwork, userProfile, full } = this.props;

    if (!artwork.id) {
      return null;
    }
    const iconSize = full ? 30 : 28;

    return (
      <>
        {acl.isAuthor(userProfile, artwork) && (
          <Link to={`/artwork/edit/${artwork.id}`}>
            <CommunSVGs.CogWhite width={iconSize} height={iconSize} fill="var(--primary)" />
            {full && <div className="flowfont-medium text-primary">Artwork settings</div>}
          </Link>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: state.user.privateProfile,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkActions);

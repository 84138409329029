import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import CommunSVGs from 'svgs/commun';

import './ConfirmationModal.scss';

type Props = {
  title: string,
  text: string,
  buttonLabel: string,
  onCancel: Function,
  onConfirm: Function,
};

class ConfirmationModal extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  handleAccept = () => {
    const { onConfirm } = this.props;
    if (onConfirm) onConfirm();
    this.setState({ show: false });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
    this.setState({ show: false });
  };

  render() {
    const { title, text, buttonLabel } = this.props;
    const { show } = this.state;

    return (
      <Modal show={show} onHide={this.handleCancel} centered dialogClassName="ConfirmationModal">
        <Modal.Body>
          <div className="ConfirmationModal-content text-center">
            <div className="ConfirmationModal-title h3 flowfont-title text-secondary text-center">{title}</div>
            <p
              className="ConfirmationModal-description flowfont-regular text-neutral text-center"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <p className="ConfirmationModal-exit text-center">
              <Button onClick={this.handleCancel} variant="primary">
                <CommunSVGs.ArrowLeft height="24" fill="#FFFFFF" /> Go back
              </Button>
            </p>
            <p className="ConfirmationModal-accept">
              <Button className="flowfont-regular" variant="link" onClick={this.handleAccept}>
                {buttonLabel || 'Confirm now'}
              </Button>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(ConfirmationModal);

import moment from 'moment';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { CURRENCY } from 'constants/Constants';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';
import cartTools from 'tools/cart';

import { getArtworkFiles } from 'actions/ArtworkActions';
import { showPlayer } from 'actions/PlayerActions';
import { subscribeArtwork, unsubscribeArtwork, placeQuote, showCart } from 'actions/UserActions';

import PlayerCover from 'components/Cover/PlayerCover';
import { LoadingSmall } from 'components/Loading';
import ConfirmationModal from 'components/Modal/ConfirmationModal';

import CommunSVGs from 'svgs/commun';

type Props = {
  artwork: Object,
  userProfile: Object,
  subscription: boolean,
  className: string,
};

const Header = ({ artwork, subscription, userProfile, className }: Props) => {
  const { t } = useTranslation('flow');
  const userCart = useSelector(state => state.user.cart);
  const visitorArtworkAccesses = useSelector(state => state.user.visitorArtworkAccesses);
  const isFetching = useSelector(state => state.artwork?.isFetching?.getArtworkById);
  const history = useHistory();

  const [showUnsubscribeConfirmation, setShowUnsubscribeConfirmation] = useState(false);
  const [showSubscribeConfirmation, setShowSubscribeConfirmation] = useState(false);

  const dispatch = useDispatch();

  if (!artwork) {
    return <LoadingSmall />;
  }

  const username = artwork?.author?.username || userProfile.username;
  let filesCount = artwork.totalFiles || artwork.totalFiles === 0 ? artwork.totalFiles : 'some';
  if (filesCount === 'some' && artwork.files) {
    filesCount = artwork.files.length;
  }

  const visitorArtworkAccess = visitorArtworkAccesses?.find(a => a.id === artwork.id);
  const isSubscribed = artwork?.artworkAccess?.isSubscriber;
  const isUnsubscribing = artwork?.artworkAccess?.isUnsubscribing;
  const playerParams = {
    type: 'flow',
    id: artwork.id,
    fileId: artwork?.artworkAccess?.lastFileRead?.id || visitorArtworkAccess?.lastFileRead?.id,
  };

  const unsubscribe = () => {
    const params = {
      userId: userProfile.id,
      artworkId: artwork.id,
    };
    dispatch(unsubscribeArtwork(params));
    setShowUnsubscribeConfirmation(false);
  };

  const subscribe = () => {
    const quote = cartTools.getQuoteFromCart(userCart);
    if (artwork?.artworkAccess?.isUnsubscribing || quote.artworks?.indexOf(artwork.id) < 0) {
      if (!artwork?.artworkAccess?.isUnsubscribing && artwork.price && artwork.price > 0) {
        quote.artworks.push(artwork.id);
        dispatch(placeQuote(quote, { artworkId: artwork.id, name: artwork.name }, artwork));
      } else {
        // free
        const params = {
          userId: userProfile.id,
          artworkId: artwork.id,
        };
        dispatch(subscribeArtwork(params));
      }
    } else {
      dispatch(showCart());
    }
  };

  const handleClickTitle = () => {
    if (subscription) {
      history.push(`/artwork/${username}/${artwork.uniqueName}/flow/${artwork.id}`);
    } else {
      dispatch(showPlayer(playerParams, artwork));
    }
  };

  const handleClickFiles = () => {
    dispatch(getArtworkFiles(artwork.uniqueName, 1));
  };

  const handleConfirm = () => {
    if (acl.isAuthor(userProfile, artwork)) {
      history.push(`/flow/${artwork.id}`);
    } else {
      subscribe();
    }
    setShowSubscribeConfirmation(false);
  };

  const toggleSubscribe = () => {
    const isEndOfCurrentMonth = moment().date() > 28;
    const isFree = !artwork.price || artwork.price <= 0;
    if (isSubscribed) {
      if (!isFree) {
        setShowUnsubscribeConfirmation(true);
      } else {
        unsubscribe();
      }
    } else if (!artwork?.artworkAccess?.isUnsubscribing) {
      if (!isFree && isEndOfCurrentMonth) {
        setShowSubscribeConfirmation(true);
      } else {
        handleConfirm();
      }
    } else {
      handleConfirm();
    }
  };

  return (
    <div className={`d-flex flex-nowrap ${className || ''}`}>
      <div className="Folder-header">
        <div className="fortooltip">
          <PlayerCover
            src={artworkUtils.getCoverUrl(artwork, 57)}
            playerParams={playerParams}
            artwork={artwork}
            className="Flow-cover"
            // overlay={<CommunSVGs.PlayMe width="50" height="50" fill="var(--primary)" />}
          />
        </div>

        <div className="Folder-titles">
          <h2 className="Folder-title h5 flowfont-title cursor" onClick={handleClickTitle} role="presentation">
            {artwork.flowName || t('global:default-flow-name')}
          </h2>
          <Link
            to={`/artwork/${username}/${artwork.uniqueName}/flow/${artwork.id}`}
            onClick={handleClickFiles}
            className="Pack-link fs-09"
          >
            <p className="Folder-subtitle">
              <span>
                {!subscription && t('files-number', { count: filesCount })}
                {subscription && (
                  <Link to={`/artwork/${username}/${artwork.uniqueName}`} className="Pack-link fs-09">
                    {artwork.name}
                  </Link>
                )}
              </span>
            </p>
          </Link>
          <div>
            {!!artwork.flowReadCountAvg && (
              <span className="text-neutralSubtle flowfont-regular mr-2">
                <OverlayTrigger
                  key="readfull"
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  delay={{ show: 500 }}
                  overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-readers')}</Tooltip>}
                >
                  <span className="d-inline-block">
                    {artworkUtils.formatCount(artwork.flowReadCountAvg)}{' '}
                    <CommunSVGs.Readfull width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                  </span>
                </OverlayTrigger>
              </span>
            )}
            {!!artwork.flowFeedbackCount && (
              <span className="flowfont-regular text-neutralSubtle mr-2">
                <OverlayTrigger
                  key="readfull"
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  delay={{ show: 500 }}
                  overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-reactions')}</Tooltip>}
                >
                  <span className="d-inline-block">
                    {artworkUtils.formatCount(artwork.flowFeedbackCount)}{' '}
                    <CommunSVGs.NiceWork width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                  </span>
                </OverlayTrigger>
              </span>
            )}
            {!!artwork.commentCount && (
              <span className="flowfont-regular text-neutralSubtle">
                <OverlayTrigger
                  key="readfull"
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  delay={{ show: 500 }}
                  overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-comments')}</Tooltip>}
                >
                  <span className="d-inline-block">
                    {artworkUtils.formatCount(artwork.commentCount)}{' '}
                    <CommunSVGs.Bubble width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                  </span>
                </OverlayTrigger>
              </span>
            )}
          </div>
        </div>
        <div className="Folder-back" onClick={history.goBack} role="presentation">
          <CommunSVGs.ArrowLeft width="25px" height="25px" fill="#EDEDED" />
        </div>
      </div>
      <div className="Folder-actions text-center">
        {acl.isAuthor(userProfile, artwork) ? (
          <Button
            className="Folder-button"
            variant="btn btn-outline-primary rounded-pill"
            size="sm"
            onClick={handleConfirm}
          >
            {t('settings-button')}
          </Button>
        ) : (
          <>
            {isFetching && <LoadingSmall />}
            {!isFetching && isUnsubscribing && (
              <OverlayTrigger
                key="re-subscribe"
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 500 }}
                overlay={
                  <Tooltip className="FolderFile-tooltipBlue">
                    Your subscription will be stopped at the end of the month. <br /> You may re-subscribe anytime
                    before this date.
                  </Tooltip>
                }
              >
                <Button
                  className="Folder-button"
                  id="FlowfoButtonResubscribe"
                  variant="btn btn-outline-primary rounded-pill"
                  size="sm"
                  onClick={() => toggleSubscribe()}
                >
                  {t('re-subscribe')}
                </Button>
              </OverlayTrigger>
            )}
            {!isFetching && !isUnsubscribing && (
              <Button
                className="Folder-button"
                id="FlowfoButtonSubscribeUnsubscribe"
                variant="btn btn-outline-primary rounded-pill"
                size="sm"
                onClick={() => toggleSubscribe()}
              >
                {isSubscribed ? t('unsubscribe') : t('subscribe')}
              </Button>
            )}
          </>
        )}
        {showSubscribeConfirmation && (
          <ConfirmationModal
            title={t('global:modal-subscribe-title')}
            text={t('global:modal-subscribe-text')}
            buttonLabel="Subscribe now"
            onCancel={() => setShowSubscribeConfirmation(false)}
            onConfirm={handleConfirm}
          />
        )}
        {showUnsubscribeConfirmation && (
          <ConfirmationModal
            title={t('global:modal-unsubscribe-title')}
            text={t('global:modal-unsubscribe-text')}
            buttonLabel="Unsubscribe now"
            onCancel={() => setShowUnsubscribeConfirmation(false)}
            onConfirm={unsubscribe}
          />
        )}
        <p className="Folder-price text-neutral flowfont-medium">
          {artwork.price > 0 && t('subscription-price', { price: artwork.price, CURRENCY })}
          {(!artwork.price || artwork.price <= 0) && t('free')}
        </p>
        <p className="text-neutralSubtle flowfont-medium fs-09">{t('vat')}</p>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';

import { useTranslation } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import artworkUtils from 'tools/artwork';

import Loading from 'components/Loading';

import CommunSVGs from 'svgs/commun';

import './ArtworkCardContent.scss';

type Props = {
  artwork: Object,
  className: String,
  onUpdate: Function,
};

function ArtworkCard({ artwork, className, onUpdate }: Props) {
  const { t } = useTranslation('artworkcardlist');
  if (_isEmpty(artwork)) {
    return <Loading />;
  }
  const handleCatClick = cat => () => {
    if (onUpdate) {
      onUpdate({ type: 'cat', value: cat });
    }
  };

  return (
    <div className={`ArtworkCard-content ${className || ''}`}>
      <div className="ArtworkCard-text">
        <p className="ArtworkCard-description flowfont-regular mb-0">
          {artwork.description || t('global:default-description')}
        </p>
        <div>
          {!!artwork.artworkReadCountAvg && (
            <span className="text-neutralSubtle flowfont-medium mr-2">
              <OverlayTrigger
                key="readfull"
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 500 }}
                overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-readers')}</Tooltip>}
              >
                <span className="d-inline-block">
                  {artworkUtils.formatCount(artwork.artworkReadCountAvg)}{' '}
                  <CommunSVGs.Readfull width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                </span>
              </OverlayTrigger>
            </span>
          )}
          {!!artwork.artworkFeedbackCount && (
            <span className="text-neutralSubtle flowfont-medium mr-2">
              <OverlayTrigger
                key="readfull"
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 500 }}
                overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-reactions')}</Tooltip>}
              >
                <span className="d-inline-block">
                  {artworkUtils.formatCount(artwork.artworkFeedbackCount)}{' '}
                  <CommunSVGs.NiceWork width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                </span>
              </OverlayTrigger>
            </span>
          )}
          {!!artwork.commentCount && (
            <span className="text-neutralSubtle flowfont-medium">
              <OverlayTrigger
                key="readfull"
                placement="bottom"
                trigger={['hover', 'focus']}
                delay={{ show: 500 }}
                overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-comments')}</Tooltip>}
              >
                <span className="d-inline-block">
                  {artworkUtils.formatCount(artwork.commentCount)}{' '}
                  <CommunSVGs.Bubble width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                </span>
              </OverlayTrigger>
            </span>
          )}
        </div>
        {artwork.categories && (
          <p className="ArtworkCard-categories fs-09 font-italic mt-0 mb-0">
            {artwork.categories.map(cat => (
              <span
                key={cat.name}
                className="ArtworkCard-categorie"
                onClick={handleCatClick(cat.name)}
                role="presentation"
              >
                #{cat.name}
              </span>
            ))}
          </p>
        )}
      </div>

      <div className="ArtworkCard-tagsWrapper">
        {/* <div className="ArtworkCard-tags"> */}
        <div className="ArtworkCard-tag">
          {artwork.isAdult ? <CommunSVGs.NSFW /> : <CommunSVGs.SFW fill="var(--neutralSubtle)" />}
          {artwork.isAdult ? (
            <span className="ArtworkCard-tag-nsfw text-uppercase flowfont-bold">NSFW</span>
          ) : (
            <span className="ArtworkCard-tag-sfw text-uppercase flowfont-bold">SFW</span>
          )}
        </div>

        <div className="ArtworkCard-tag">
          <CommunSVGs.Lang fill="var(--neutralSubtle)" />
          <span className="text-uppercase text-neutralSubtle flowfont-bold">
            {artwork.language ? artwork.language.name : 'N/A'}
          </span>
        </div>

        <div className="ArtworkCard-tag">
          <CommunSVGs.Tag fill="var(--neutralSubtle)" />
          <span className="text-neutralSubtle text-uppercase flowfont-bold">{artwork.mediaType || 'Other'}</span>
        </div>

        {artwork.website && (
          <div className="ArtworkCard-tag ArtworkCard-tag-website">
            <CommunSVGs.WebPage fill="var(--primary)" />
            <a
              href={(artwork.website.includes('http') ? '' : 'http://') + artwork.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary text-uppercase flowfont-bold"
            >
              web
            </a>
          </div>
        )}
        {/* </div> */}
      </div>
    </div>
  );
}

export default ArtworkCard;

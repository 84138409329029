const payment = {
  roundNumber: number => Math.round(number * 100) / 100,
  computeIncome: price => {
    if (!price) return 0;
    const fees = price >= 1 ? price * 0.109 + 0.3 : 0;
    return payment.roundNumber(price - payment.roundNumber(fees));
  },
};

export default payment;

import cx from 'classnames';

import React, { memo, useState } from 'react';

import artworkUtils from 'tools/artwork';

import imgLoadFailed from 'components/Player/PlayerSlide/embeded';

import './Cover.scss';

type Props = {
  className: string,
  src: string,
  srcLq: string,
  noLazy: Boolean,
  width: Number,
  alt: string,
  title: string,
  noClass: Boolean,
  overlay: Object,
  overlayTrigger: String,
  onClick: () => void,
};

const Cover = ({
  className,
  src,
  srcLq,
  noLazy,
  width,
  alt,
  title,
  noClass,
  overlay,
  overlayTrigger,
  onClick,
}: Props) => {
  const [imgError, setImgError] = useState(false);
  const srcLazy = srcLq || artworkUtils.getCoverUrl(false, width || 167, null, null, 50);
  const srcUpdated = noLazy ? src : srcLazy;
  return (
    <>
      <div
        key={src}
        className={cx({ Cover: !noClass, [className]: className, [`Cover-overlay-${overlayTrigger}`]: overlayTrigger })}
      >
        <img
          src={imgError === true ? imgLoadFailed : srcUpdated}
          data-src={src}
          alt={alt}
          title={title}
          className={cx('Cover-image', 'lazyload', { 'Cover-link': !!onClick })}
          onClick={onClick}
          role="presentation"
          onError={e => {
            if (e.target.src !== imgLoadFailed) {
              setImgError(true);
            }
          }}
        />
        {overlay && (
          <div className={cx('Cover-overlay', { 'Cover-link': !!onClick })} role="presentation" onClick={onClick}>
            {overlay}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Cover);

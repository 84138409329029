import moment from 'moment';

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';

/* import CommunSVGs from 'svgs/commun'; */
import { CURRENCY } from 'constants/Constants';

import artworkUtils from 'tools/artwork';
import payment from 'tools/payment';

import { getIncomes } from 'actions/UserActions';

import Avatar from 'components/Avatar';
import Cover from 'components/Cover';
import Loading from 'components/Loading';

import './Incomes.scss';

export default function Incomes() {
  const dispatch = useDispatch();
  const { t } = useTranslation('incomes');

  const userIncomes = useSelector(state => state.user.incomes);
  const userIncomesTotal = useSelector(state => state.user.incomesTotal);
  const userIncomesLast = useSelector(state => state.user.incomesLast);
  const userIncomesFetching = useSelector(state => state.user?.isFetching?.incomes);
  const userSubscriptionBillings = useSelector(state => state.user.subscriptionBillings);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getIncomes());
  }, [dispatch]);

  const getRefNumber = income => {
    return `${income.id}`.padStart(10, '0');
  };

  const renderPurchase = purchase => {
    const isFree = !purchase.netPrice || purchase.netPrice <= 0;
    return (
      <div className="Incomes-Pack d-flex" key={`pp${purchase.id}`}>
        <Cover
          src={artworkUtils.getCoverUrl(_get(purchase, 'pack'), 24)}
          alt={_get(purchase, 'pack.name')}
          className="Incomes-Pack-cover"
        />
        <div className="Incomes-Pack-info">
          <div className="Incomes-Pack-name">
            {/* <CommunSVGs.Pack width="24" height="24" fill="var(--secondary)" /> */}
            <Link
              to={`/artwork/${_get(purchase, 'artworkAuthor.username')}/${_get(
                purchase,
                'pack.artwork.uniqueName',
              )}/pack/${_get(purchase, 'pack.id')}`}
            >
              {_get(purchase, 'pack.name') || 'Unknown Pack'}
            </Link>
          </div>
          <div className="Incomes-Pack-price">
            {!isFree && `${purchase.netPrice} ${CURRENCY}`}
            {isFree && 'Free'}
          </div>
          <div className="Incomes-Pack-artwork">
            <Link
              to={`/artwork/${_get(purchase, 'artworkAuthor.username')}/${_get(purchase, 'pack.artwork.uniqueName')}`}
            >
              {_get(purchase, 'pack.artwork.name') || 'Artwork'}
            </Link>
          </div>
          <div className="Incomes-transaction">
            {purchase.purchaseDate && moment(purchase.purchaseDate).format('DD/MM/YYYY - HH:mm')} - Ref{' '}
            {getRefNumber(purchase)}
          </div>
          {/* <div className="Incomes-Pack-payout-ref">
            Ref {getRefNumber(purchase)} - VAT {_get(purchase, 'country.code')} {_get(purchase, 'country.vat')}%
              </div> */}
          <Link to={`/profile/${_get(purchase, 'user.username')}#artworks`} className="Incomes-user">
            <Avatar
              src={artworkUtils.getAvatarUrl(_get(purchase, 'user.avatarFile'), 18)}
              alt="User"
              className="Incomes-avatar border border-neutralSubtle"
            />
            {_get(purchase, 'user.username') || 'unknown user'}
          </Link>
        </div>
        <div className="Incomes-payout">
          <div className="Incomes-payout-label">For me :</div>
          <div className="Incomes-payout-balance">
            {payment.computeIncome(purchase.netPrice).toFixed(2)} {CURRENCY}
          </div>
          <div className="Incomes-payout-status">
            <div>{purchase.payoutStatus}</div>
            {purchase.payoutDate && moment(purchase.payoutDate).format('DD/MM/YYYY')}
          </div>
        </div>
      </div>
    );
  };

  const renderSubscriptionBillings = sb => {
    const isFree = !sb.netPrice || sb.netPrice <= 0;
    return (
      <div className="Incomes-Flow d-flex" key={`sb${sb.id}`}>
        <Cover
          src={_get(sb, 'artwork.coverFile.publicThumbnailUrl')}
          alt={_get(sb, 'artwork.name')}
          className="Incomes-Flow-cover"
        />
        <div className="Incomes-Flow-info">
          <div className="Incomes-Flow-name">
            <Link
              to={`/artwork/${_get(sb, 'artworkAuthor.username')}/${_get(sb, 'artwork.uniqueName')}/flow/${_get(
                sb,
                'artwork.id',
              )}`}
            >
              {_get(sb, 'artwork.flowName') || t('global:default-flow-name')}
            </Link>
          </div>
          <div className="Incomes-Flow-price">
            {!isFree && `${sb.netPrice} ${CURRENCY}`}
            {isFree && '0.00'}
            <div className="Incomes-Flow-price-subprice">per month</div>
          </div>
          <div className="Incomes-Flow-artwork">
            <Link to={`/artwork/${_get(sb, 'artworkAuthor.username')}/${_get(sb, 'artwork.uniqueName')}`}>
              {_get(sb, 'artwork.name') || 'Subscription'}
            </Link>
          </div>
          <div className="Incomes-transaction">
            {sb.createdAt && moment(sb.createdAt).format('DD/MM/YYYY - HH:mm')} - Ref {getRefNumber(sb)}
          </div>
          <Link to={`/profile/${_get(sb, 'user.username')}#artworks`} className="Incomes-user">
            <Avatar
              src={artworkUtils.getAvatarUrl(_get(sb, 'user.avatarFile'), 18)}
              alt="User"
              className="Incomes-avatar border border-neutralSubtle"
            />
            {_get(sb, 'user.username') || 'unknown user'}
          </Link>
        </div>
        <div className="Incomes-payout">
          <div className="Incomes-payout-label">For me :</div>
          <div className="Incomes-payout-balance">
            {payment.computeIncome(sb.netPrice).toFixed(2)} {CURRENCY}
          </div>
          <div className="Incomes-payout-status">
            <div>{sb.payoutStatus}</div>
            {sb.payoutDate && moment(sb.payoutDate).format('DD/MM/YYYY')}
          </div>
        </div>
      </div>
    );
  };

  const loadMore = async () => {
    const currentPage = page + 1;
    if (userIncomesFetching || (userIncomesLast && currentPage > userIncomesLast)) {
      return;
    }
    setPage(currentPage);
    await dispatch(getIncomes(currentPage));
  };

  // let balance = 0;
  // balance = balance.toFixed(2);
  const isEmpty = _isEmpty(userIncomes) && _isEmpty(userSubscriptionBillings);
  let incomes = userIncomes || [];
  if (userSubscriptionBillings) {
    incomes = [...incomes, ..._map(userSubscriptionBillings, sb => ({ ...sb, purchaseDate: sb.createdAt }))];
  }
  incomes = _orderBy(incomes, ['purchaseDate', 'id'], ['desc']);
  return (
    <div className="Incomes">
      {/* <div className="Incomes-balance">
          TO PAY YOU {balance}
          {CURRENCY}
        </div> */}
      <div className="Incomes-info">
        {t('info-text1')}
        <br />
        {t('info-text2')}
        <br />
        <Link to="/prices">{t('info-price-link')}</Link> {t('info-text3')}
      </div>
      <div className="Incomes-settings">
        <Link to="/profile/settings#chekoutMethod">{t('Choose the method to get paid.')}</Link>
      </div>
      {!userIncomes && !userSubscriptionBillings && <Loading fullPage />}
      {isEmpty && <div className="mt-4 flowfont-medium font-italic">Empty</div>}
      {incomes && (
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={(userIncomes || []).length < userIncomesTotal}
          loader={<Loading key={0} />}
          initialLoad={false}
          className="Incomes-wrapper"
        >
          {incomes.map(income => {
            if (income.pack) return renderPurchase(income);
            if (income.artwork) return renderSubscriptionBillings(income);
            return null;
          })}
        </InfiniteScroll>
      )}
    </div>
  );
}

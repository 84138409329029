import { SHOW_PLAYER, HIDE_PLAYER } from 'constants/ActionTypes';

export function showPlayer(params, artwork = null, noDisclaimer = false) {
  return {
    type: SHOW_PLAYER,
    params,
    artwork,
    noDisclaimer,
  };
}

export function hidePlayer() {
  return {
    type: HIDE_PLAYER,
  };
}

import loadable from '@loadable/component';

import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Button from 'react-bootstrap/Button';

import { MEDIA_LG } from 'constants/Constants';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';
import seo from 'tools/seo';

import { getArtworkById, getSuggestions, markNotInterested, MODE_FULLPAGE } from 'actions/ArtworkActions';

import ArtworkDisclaimer from 'components/Artwork/ArtworkDisclaimer';
import ArtworkHeaderActions from 'components/Artwork/ArtworkHeaderActions';
import Banner from 'components/Banner';
import { CustomTabs, CustomTab } from 'components/CustomTabs';
import ErrorPage from 'components/ErrorPage';
import Loading from 'components/Loading';
import Redirect from 'components/Routes/Redirect';
import ArtworkSuggestions from 'components/Suggestions/ArtworkSuggestions';

import CommunSVGs from 'svgs/commun';

import '../Page.scss';
import './Artwork.scss';
import ArtworkFlows from './partials/ArtworkFlows';
import ArtworkProfile from './partials/ArtworkProfile';

const FolderPage = loadable(() => import('components/Folder/FolderPage'), { fallback: <Loading /> });
const PlayerModal = loadable(() => import('components/Player/PlayerModal'));
const NoMatch404 = loadable(() => import('pages/NoMatch404'), { fallback: <Loading fullPage /> });
const ArtworkNews = loadable(() => import('./partials/ArtworkNews'), { fallback: <Loading fullPage /> });

type Props = {
  isNews: Boolean,
};

const Artwork = ({ isNews }: Props) => {
  const { t } = useTranslation('artwork');
  // store selectors
  const userProfile = useSelector(state => state.user.privateProfile);
  const acceptedArtworks = useSelector(state => state.user.acceptedArtworks);
  const visitorArtworkAccesses = useSelector(state => state.user.visitorArtworkAccesses);
  const visitorPackAccesses = useSelector(state => state.user.visitorPackAccesses);
  const artwork = useSelector(state => state.artwork.current);
  const suggestions = useSelector(state => state.artwork.suggestions);
  const isFetching = useSelector(state => _get(state.artwork, 'isFetching.getArtworkById'));
  const error = useSelector(state => state.artwork.error);
  const packError = useSelector(state => state.pack.error);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });
  // actions
  const dispatch = useDispatch();

  const [accepted, setAccepted] = React.useState(false);
  const [showPlayer, setShowPlayer] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState();

  const getCurrentTab = () => {
    if (activeKey) {
      return activeKey;
    }
    let defaultActiveKey = 'flowPack';
    const anchor = location.hash.substr(1);
    if (anchor) {
      defaultActiveKey = anchor.replace(/\?.*/, '');
    } else if (isNews) {
      defaultActiveKey = 'news';
    } else {
      defaultActiveKey = 'flowPack';
    }
    return defaultActiveKey;
  };

  const isUserArtwork = acl.isAuthor(userProfile, artwork);
  const defaultActiveKey = getCurrentTab();
  const isGallery = defaultActiveKey === 'flowPack' && params.folderType;
  const isTeaser = defaultActiveKey === 'flowPack' && !params.folderType;
  const hasSuggestions = !isGallery && suggestions && suggestions.length > 0;

  const loadArtwork = (forceUpdate, mode) => {
    dispatch(getArtworkById(params.artworkName, forceUpdate, mode));
    dispatch(getSuggestions(params.artworkName));
  };

  const needRefresh = error?.getArtworkById !== 404 && (!artwork?.id || artwork?.uniqueName !== params.artworkName);
  if (typeof document === 'undefined' && needRefresh) {
    // SSR
    loadArtwork();
  }

  useEffect(() => {
    // component did mount (need refresh after SSR reload)
    if (needRefresh || acl.isConnected(userProfile)) {
      loadArtwork();
    }
  }, []);

  useEffect(() => {
    if (!isFetching && needRefresh) {
      loadArtwork(false, MODE_FULLPAGE);
    }
  }, [artwork, error, params, userProfile, isFetching, dispatch]);

  const handleNotInterested = id => {
    dispatch(markNotInterested(id));
  };

  const renderPlayMeModal = playerParams => {
    if (!playerParams || !showPlayer) return null;

    return (
      <PlayerModal
        playerParams={playerParams}
        artwork={artwork}
        noDisclaimer={accepted}
        onClose={() => {
          setShowPlayer(false, () => dispatch(getArtworkById(artwork.uniqueName)));
        }}
      />
    );
  };

  const renderPlayMeTab = playerParams => {
    const button = (
      <Button
        size="sm"
        variant="secondary rounded-pill Artwork-PlaymeButton"
        disabled={!playerParams}
        className="Artwork-PlaymeButton"
      >
        <CommunSVGs.PlayMe
          height="20"
          width="20"
          className="Artwork-PlayMeSVG"
          onClick={() => {
            setShowPlayer(true);
          }}
        />
        <span className="ml-2 Artwork-Playme">{t('global:read')}</span>
      </Button>
    );
    return <CustomTab eventKey="playMe" title={button} />;
  };

  if (isFetching || (error?.getArtworkById !== 404 && (!artwork?.id || artwork?.uniqueName !== params.artworkName))) {
    return <Loading fullPage />;
  }
  if (error?.getArtworkById === 404 || (params.folderType === 'pack' && _get(packError, 'getPackById') === 404)) {
    return <NoMatch404 location={location} history={history} />;
  }
  if (error?.getArtworkById) {
    return <ErrorPage />;
  }
  const authorName = _get(artwork, 'author.username');
  if (authorName && authorName !== params.userName) {
    const newPath = location.pathname.replace(/\/artwork\/[^/]+/, `/artwork/${authorName}`);
    return <Redirect to={newPath + location.hash + location.search} code={301} />;
  }
  const urlFlows = location.pathname.replace('/news', '');
  const urlNews = `${urlFlows}/news`;

  let classNamePageContent = 'Page-content ';
  if (isGallery) classNamePageContent += 'Artwork-gallery';
  if (isTeaser) classNamePageContent += 'Artwork-teaser';
  if (hasSuggestions) classNamePageContent += ' Artwork-suggest';
  const showDisclaimer = artworkUtils.isDisclaimerNeeded(userProfile, artwork, acceptedArtworks, accepted);
  const playerParams = artworkUtils.computePlayerParams(artwork, visitorArtworkAccesses, visitorPackAccesses);
  const handleStartFlow = () => {
    history.push(`/flow/${artwork.id}`);
  };

  return (
    <div
      className={`Page d-flex flex-column flex-lg-row-reverse justify-content-around ${
        showDisclaimer ? 'Page-blur' : ''
      }`}
    >
      <Helmet>
        {seo.title(`${_get(artwork, 'name') || ''} is created by ${_get(artwork, 'author.username') || ''} - Flowfo`)}
        {seo.description(
          `${_get(artwork, 'description') || t('global:default-description')}` +
            ` ${artwork.categories.map(cat => `#${cat.name}`).join(' ')} - comics, bd, mangas, webtoons.`,
        )}
        {seo.image(artworkUtils.getCoverUrl(artwork))}
        {seo.pageLang(artworkUtils.getLang(artwork))}
      </Helmet>
      {hasSuggestions && (
        <div className="Page-suggestions d-none d-xl-flex flex-column flex-sm-row flex-lg-column justify-content-center justify-content-lg-start">
          <ArtworkSuggestions
            suggestions={suggestions}
            userProfile={userProfile}
            onNotInterested={handleNotInterested}
          />
        </div>
      )}
      <div
        className={`Page-card  ${
          hasSuggestions ? 'Artwork-suggest' : ''
        } d-none d-lg-flex flex-column flex-sm-row flex-lg-column justify-content-center justify-content-lg-start`}
      >
        {showDisclaimer && (
          <ArtworkDisclaimer artwork={artwork} userProfile={userProfile} onAccept={() => setAccepted(true)} />
        )}
        <ArtworkProfile artwork={artwork} userProfile={userProfile} isGallery={isGallery} />
      </div>
      <div className={classNamePageContent}>
        <div className="Artwork-header">
          <div className="Artwork-actions text-right d-block d-lg-none">
            <ArtworkHeaderActions artwork={artwork} />
          </div>
          <Banner
            className="Artwork-banner"
            isArtworkPage
            isGalleryPage={isGallery}
            artwork={artwork}
            userProfile={userProfile}
            onGoBack={() => history.goBack()}
          />
        </div>
        {params.folderType && <FolderPage type={params.folderType} userProfile={userProfile} />}
        {!params.folderType && (
          <CustomTabs
            id="artworkTabs"
            defaultActiveKey={defaultActiveKey}
            activeKey={activeKey}
            onSelect={key => {
              if (key === 'playMe') {
                setShowPlayer(!!playerParams);
                setActiveKey('flowPack');
              } else {
                setShowPlayer(false);
                // setActiveKey(key);
                if (key === 'news') {
                  history.push(urlNews);
                } else {
                  history.push(urlFlows);
                }
              }
            }}
          >
            {(isUserArtwork || artwork.totalNews > 0 || isMobile) && (
              <CustomTab
                eventKey="news"
                title={
                  <span className="position-relative">
                    <span>News</span>
                    {artwork.newsUnReadCount > 0 && (
                      <div className="Artwork-tab-badge">
                        {/* {artwork.newsUnReadCount > 9 ? '9+' : artwork.newsUnReadCount} */}
                      </div>
                    )}
                  </span>
                }
                href={urlNews}
                className="Tab Tab-News"
              >
                {defaultActiveKey === 'news' && <ArtworkNews artwork={artwork} userProfile={userProfile} />}
              </CustomTab>
            )}
            <CustomTab eventKey="flowPack" title="Flows" href={urlFlows} className="Tab">
              {defaultActiveKey === 'flowPack' && (
                <ArtworkFlows artwork={artwork} userProfile={userProfile} onStartFlow={handleStartFlow} />
              )}
            </CustomTab>
            {(artwork.isFlow || !_isEmpty(artwork.packs)) && renderPlayMeTab(playerParams)}
          </CustomTabs>
        )}
        {hasSuggestions && (
          <div className="tab-content d-xl-none d-flex">
            <div className="Folder">
              <ArtworkSuggestions
                suggestions={suggestions}
                userProfile={userProfile}
                onNotInterested={handleNotInterested}
              />
            </div>
          </div>
        )}
        {renderPlayMeModal(playerParams)}
      </div>
    </div>
  );
};

export default Artwork;

import loadable from '@loadable/component';
import moment from 'moment';

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';

import { CURRENCY } from 'constants/Constants';

import { placeQuoteMonthly } from 'actions/UserActions';

import Header from 'components/Folder/Flow/Header';
import Loading, { LoadingSmall } from 'components/Loading';

import './NextMonth.scss';

const InputCreditCard = loadable(() => import('components/Form/InputCreditCard'), { fallback: <LoadingSmall /> });

export default function Incomes() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('nextmonth');

  const userProfile = useSelector(state => state.user.privateProfile);
  const quoteMonthly = useSelector(state => state.user.quoteMonthly);

  useEffect(() => {
    dispatch(placeQuoteMonthly());
  }, [dispatch]);

  const renderSubscriptionBillings = (sb, key) => {
    const data = {
      ...sb.artwork,
      author: sb.artworkAuthor,
      price: sb.netPrice,
    };
    return <Header artwork={data} userProfile={userProfile} key={sb.id + key} className="mt-3" subscription />;
  };

  let balance = _get(quoteMonthly, 'netPrice') + _get(quoteMonthly, 'vatPrice');
  if (balance) balance = balance.toFixed(2);
  let vatPrice = _get(quoteMonthly, 'vatPrice');
  if (vatPrice) vatPrice = vatPrice.toFixed(2);
  const vatCountry = _get(quoteMonthly, 'country.code');
  const vatPourcent = _get(quoteMonthly, 'country.vat');

  const nextMonthName = moment().add(1, 'months').locale(i18n.language).format('MMMM');

  return (
    <div className="NextMonth">
      <div className="NextMonth-balance">
        <div>{t('balance-text1', { nextMonthName })}</div>
        <div>{t('balance-text2', { balance, CURRENCY })}</div>
      </div>
      <div className="NextMonth-info">
        All your paying subscriptions are grouped here.
        <br />
        Thanks for your support :)
      </div>
      {!_get(quoteMonthly, 'subscriptionBillings') && <Loading fullPage />}
      {_get(quoteMonthly, 'subscriptionBillings') && quoteMonthly.subscriptionBillings.length === 0 && (
        <div className="mt-4 flowfont-medium">Empty</div>
      )}
      {_get(quoteMonthly, 'subscriptionBillings') &&
        quoteMonthly.subscriptionBillings.map((sb, key) => renderSubscriptionBillings(sb, key))}
      <div className="d-flex NextMonth-vat">
        <div className="NextMonth-vatpourcent">
          + VAT {vatCountry} {vatPourcent}%
        </div>
        <div className="NextMonth-vatprice">
          {vatPrice} {CURRENCY}
        </div>
      </div>
      <div className="NextMonth-payment">
        {_get(userProfile, 'billingFailure') && (
          <div className="alert alert-danger p-3 mt-5">Check your payment method below</div>
        )}
        <InputCreditCard userProfile={userProfile} />
      </div>
    </div>
  );
}

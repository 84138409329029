import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { CustomTabs, CustomTab } from 'components/CustomTabs';

import CommunSVGs from 'svgs/commun';

import Billings from './Billings';
import Incomes from './Incomes';
import NextMonth from './NextMonth';
import './Payment.scss';

export default function Payment() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('payment');

  // const [ key ] = useState();

  const getCurrentTab = () => {
    // if (key) {
    //   return key;
    // }
    let defaultActiveKey = 'nextmonth';
    if (location.hash.substr(1)) {
      defaultActiveKey = location.hash.substr(1);
    }
    return defaultActiveKey;
  };

  const defaultActiveKey = getCurrentTab();
  return (
    <div className="Payment Page Page-full">
      <div className="Payment-back" onClick={history.goBack} role="presentation">
        <CommunSVGs.ArrowLeft width="25px" height="25px" fill="#EDEDED" />
      </div>
      <CustomTabs id="payment" defaultActiveKey={defaultActiveKey} onSelect={key => history.push(`#${key}`)}>
        <CustomTab eventKey="nextmonth" title={t('nextmonth-tab')}>
          <NextMonth />
        </CustomTab>
        <CustomTab eventKey="billings" title={t('billings-tab')}>
          <Billings />
        </CustomTab>
        <CustomTab eventKey="incomes" title={t('incomes-tab')}>
          <Incomes />
        </CustomTab>
      </CustomTabs>
    </div>
  );
}

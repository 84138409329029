import React from 'react';

import { Route } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = 500;
        return (
          <div className="text-center pt-5">
            <img src="/assets/images/error_500.svg" alt="Something goes wrong..." />
          </div>
        );
      }}
    />
  );
};

export default React.memo(ErrorPage);

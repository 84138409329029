import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { showPlayer } from 'actions/PlayerActions';

import Cover from './Cover';
import './Cover.scss';

type Props = {
  className: string,
  src: string,
  srcLq: string,
  noLazy: boolean,
  width: number,
  alt: string,
  title: string,
  noClass: boolean,
  overlay: Object,
  overlayTrigger: string,
  to: string,
  playerParams: Object,
  artwork: Object,
};

export default function PlayerCover({ playerParams, to, artwork, ...props }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleShow = () => {
    if (playerParams) {
      dispatch(showPlayer(playerParams, artwork));
      // helpscout.hide();
      // this.setState({ show: true, scrollPosition: document.documentElement.scrollTop });
    } else if (to) {
      history.push(to);
    }
  };

  return <Cover {...props} onClick={playerParams || to ? handleShow : false} />;
}

import moment from 'moment';

import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import _get from 'lodash/get';
import _map from 'lodash/map';

import { CURRENCY } from 'constants/Constants';

import artworkUtils from 'tools/artwork';
import cartUtils from 'tools/cart';

import Cover from 'components/Cover';

import CommunSVGs from 'svgs/commun';

import './Cart.scss';

type Props = {
  cart: Object,
  readOnly: Boolean,
  withoutTotal: Boolean,
  className: string,
  handleRemove: Function,
};

class Cart extends Component<Props> {
  renderPurchasePack = purchase => {
    const { readOnly, handleRemove } = this.props;
    const isFree = !purchase.netPrice || purchase.netPrice <= 0;
    return (
      <div className="Cart-Pack d-flex" key={`${_get(purchase, 'id')}-${_get(purchase, 'pack.id')}`}>
        <Cover
          src={artworkUtils.getCoverUrl(_get(purchase, 'pack'), 24)}
          alt={_get(purchase, 'pack.name')}
          className="Cart-Pack-cover"
        />
        <div className="Cart-Pack-info">
          <div className="Cart-Pack-name">
            {/* <CommunSVGs.Pack width="24" height="24" fill="var(--secondary)" /> */}
            <Link
              to={`/artwork/${_get(purchase, 'artworkAuthor.username')}/${_get(
                purchase,
                'pack.artwork.uniqueName',
              )}/pack/${_get(purchase, 'pack.id')}`}
            >
              {_get(purchase, 'pack.name') || 'Unknown Pack'}
            </Link>
          </div>
          <div className="Cart-Pack-artwork">
            <Link
              to={`/artwork/${_get(purchase, 'artworkAuthor.username')}/${_get(purchase, 'pack.artwork.uniqueName')}`}
              className="text-secondary"
            >
              {_get(purchase, 'pack.artwork.name') || _get(purchase, 'artwork.name') || 'Unknown Artwork'}
            </Link>
          </div>
        </div>
        <div className="Cart-Pack-price">
          {!isFree && `${purchase.netPrice} ${CURRENCY}`}
          {isFree && 'Free'}
        </div>
        {!readOnly && (
          <div className="Cart-Pack-actions">
            <CommunSVGs.Bin width="24" height="24" fill="var(--secondary)" onClick={() => handleRemove(purchase)} />
          </div>
        )}
      </div>
    );
  };

  renderSubscriptionFlow = flow => {
    const { readOnly, handleRemove, t } = this.props;
    const isFree = !flow.netPrice || flow.netPrice <= 0;
    return (
      <div className="Cart-Flow d-flex" key={JSON.stringify(flow)}>
        <Cover
          src={artworkUtils.getCoverUrl(_get(flow, 'artwork'), 24)}
          alt={_get(flow, 'artwork.name')}
          className="Cart-Flow-cover"
        />
        <div className="Cart-Flow-info">
          <div className="Cart-Flow-name">
            <Link
              to={`/artwork/${_get(flow, 'artworkAuthor.username')}/${_get(flow, 'artwork.uniqueName')}/flow/${_get(
                flow,
                'artwork.id',
              )}`}
            >
              {_get(flow, 'artwork.flowName') || t('global:default-flow-name')}
            </Link>
          </div>
          <div className="Cart-Flow-subscription">
            <Link to={`/artwork/${_get(flow, 'artworkAuthor.username')}/${_get(flow, 'artwork.uniqueName')}`}>
              {_get(flow, 'pack.artwork.name') || _get(flow, 'artwork.name') || 'Subscription'}
            </Link>
          </div>
        </div>
        <div className="Cart-Flow-price">
          <div className="Cart-Flow-netprice">
            {!isFree && `${flow.netPrice} ${CURRENCY}`}
            {isFree && 'Free'}
          </div>
          <div className="Cart-Flow-subprice">per month</div>
        </div>
        {!readOnly && (
          <div className="Cart-Flow-actions">
            <CommunSVGs.Bin width="24" height="24" fill="var(--primary)" onClick={() => handleRemove(flow)} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { cart, withoutTotal, className, t } = this.props;
    if (!cart) {
      return <div>Empty !</div>;
    }
    const vatCountry = cart.country?.code;
    const vatPourcent = cart.country?.vat;
    let totalPrice = cart.netPrice;
    let { vatPrice } = cart;
    totalPrice += vatPrice;
    vatPrice = vatPrice.toFixed(2);
    totalPrice = totalPrice.toFixed(2);
    const cartDate = new Date();
    const cartSize = cartUtils.getCartSize(cart);

    return (
      <div className={`${className || ''} Cart`}>
        <div className="Cart-description flowfont-regular text-neutral text-center">
          {cart && _map(cart.packAccesses, pack => this.renderPurchasePack(pack))}
          {cart && _map(cart.subscriptionBillings, flow => this.renderSubscriptionFlow(flow))}
          {cartSize === 0 && <div>Empty !</div>}
          {!cart && <div>Loading...</div>}
          <div className="d-flex Cart-vat">
            <div className="Cart-vatpourcent">{!!vatPourcent && `${t('vat')} ${vatCountry} ${vatPourcent}%`}</div>
            <div className="Cart-vatprice">{!!vatPourcent && `${vatPrice} ${CURRENCY}`}</div>
          </div>
        </div>
        {!withoutTotal && (
          <div className="d-flex Cart-total">
            <div className="Cart-totaldate">{moment(cartDate).format('DD/MM/YYYY')}</div>
            <div className="Cart-totalprice">
              {totalPrice} {CURRENCY}
            </div>
          </div>
        )}
        {!vatPourcent && (
          <div className="d-flex Cart-vat justify-content-end">
            <div className="Cart-vatprice">+VAT</div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('cart')(Cart);
